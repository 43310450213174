import React, { useState } from 'react';
import { Spin } from 'antd';
import './urlModal.scss';
import strings from '../../../utils/strings.json';
import Modal from '../../common/modal/modal';
import { isValidUrl } from '../../partials/homeComponent/filesConvert/helper';

const UrlModal = ({ visible, onClose, handleGetURL, isLoading }) => {
  const [url, setUrl] = useState(''); // State to store input value
  const [error, setError] = useState(false); // State to store error message

  const handleChange = (e) => {
    setUrl(e.target.value); // Update state when input changes
    setError('');
  };

  const handleAddClick = () => {
    if (!url || url.trim() === '') {
      setError(strings.INVALID_URL);
      return;
    }
    if (!isValidUrl(url)) {
      setError(strings.URL_ERROR); // Show error if the URL is invalid
      return;
    }
    handleGetURL(url, onClose, setError, setUrl); // Pass the input value to handleGetURL when "Add" is clicked
    setError('');
  };

  const handleClose = () => {
    onClose();
    setUrl();
    setError('');
  };

  return (
 <Modal
    isOpen={visible}
    onOk={onClose}
    onClose={handleClose}
    className="url-info-wrap"
    maskClosable={false}
    closeIcon={false}
  >
    <Spin spinning={isLoading}>
      <div className="url-modal-section">
        <div className="url-modal-title">
          <span>{strings.GET_URL}</span>
        </div>
        <div className="wrap-top-modal">
          <div className="body-modal">
            <div className="modal-url-form">
              <label>{strings.PASTE_URL}</label>
              <input
                placeholder={strings.URL_TEXT}
                value={url}
                onChange={handleChange}
                className={`custom-input ${error ? 'input-error' : ''}`}
              />
              {error && <div className="error-message">{error}</div>}
              <div className="submit-btn-modal">
                <button className="hl_cta_wrap" onClick={handleAddClick}>
                  {strings.CONTINUE}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  </Modal>
  );
};

export default UrlModal;
