import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const convertFile = createAsyncThunk('convertFile', async (data) => {
  try {
    const response = await AxiosInstance.post(`/file/convert-sync`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const convertFileSlice = createSlice({
  name: 'convertFile',
  initialState: {
    isLoading: false,
    convertFileData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(convertFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(convertFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.convertFileData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(convertFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default convertFileSlice.reducer;
