import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const notificationList = createAsyncThunk('notificationList', async ({page}) => {
  try {
    const params = new URLSearchParams();
    if (page !== undefined && page !== null) {
      params.append('page', page);
    }
    const queryString = params.toString() ? `?${params.toString()}` : '';
    const response = await AxiosInstance.get(`/notification/list${queryString ? queryString : ''}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response?.data?.message || error.response?.data?.detail;
    } else {
      throw error;
    }
  }
});

const notificationListSlice = createSlice({
  name: 'notificationList',
  initialState: {
    isLoading: false,
    notificationList: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(notificationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notificationList = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(notificationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action?.error?.message || action?.error?.detail;
      });
  }
});

export default notificationListSlice.reducer;
