import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const fetchFileMetadata = createAsyncThunk(
  'files/fetchFileMetadata',
  async (inputFormats, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      let url = '/file/file-metadata';
      if (inputFormats !== undefined && inputFormats !== null) {
        params.append('inputFormats', inputFormats);
        url += `?inputFormats=["${inputFormats}"]`;
      } else {
        url += `?inputFormats=[]`;
      }
      const response = await AxiosInstance.get(url);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching metadata');
    }
  }
);
const fileMetadataSlice = createSlice({
  name: 'fileMetadata',
  initialState: {
    data: [],
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileMetadata.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFileMetadata.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchFileMetadata.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default fileMetadataSlice.reducer;
