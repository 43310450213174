import React from 'react';
import { Cancle_button, down_arrows, format_icon, right_arrows } from '../../../assets/images/dashboard';
import Modal from '../../../components/common/modal/modal';
import { ConverterDropdown } from '../../../components/partials/homeComponent/filesConvert/converterDropdown';
import strings from '../../../utils/strings.json';
import { FaExclamationTriangle } from 'react-icons/fa';
import { capitalizeFirstLetter } from '../../../utils/commonUtils';
import FileIcon from '../../../components/fileIcon/fileIcon';
import './modalListing.scss';

const ModalListingComponent = (props) => {
  const {
    handleBatchConvert,
    isShowConvertPopup,
    onCloseConvertPopUp,
    selectedFiles,
    modalConvertOptions,
    toggleModalDropdown,
    onFileMetadata,
    modalOpenDropdowns,
    handleSelectModalConvertTo,
    allFilesSameFormat,
    metadata,
    isLoading,
    fileErrors,
    handleDeleteSelectedFiles
  } = props;

  return (
    <Modal
      isOpen={isShowConvertPopup}
      onOk={isShowConvertPopup}
      onClose={() => onCloseConvertPopUp(false)}
      footer={null}
      className='modal-content urL-modal'
    >
      <div className='modal-listing-outer'>
        <div className='modal-title'>
          <h2>Files Converter</h2>
        </div>
        <div className='checkbox-container'>
          {allFilesSameFormat() && (
            <>
              <button
                className='convert-to-btn'
                onClick={() => {
                  onFileMetadata('rtf');
                  toggleModalDropdown(1);
                }}
              >
                <img src={format_icon} alt='Format Icon' />
                <span
                  style={{
                    textTransform: modalConvertOptions && modalConvertOptions[1] && 'uppercase'
                  }}
                >
                  {(modalConvertOptions && modalConvertOptions[1]) || 'Convert all to'}
                </span>
                <img src={down_arrows} alt='' />
                {modalOpenDropdowns[1] && (
                  <ConverterDropdown
                    setIsOpen={() => toggleModalDropdown(1)}
                    onSelectConvertTo={(option) => {
                      handleSelectModalConvertTo(1, option);
                      toggleModalDropdown(1);
                    }}
                    metadata={metadata}
                    isLoading={isLoading}
                  />
                )}
              </button>
            </>
          )}
        </div>
        {selectedFiles.slice(0, 10).map(({ name, size, convertedFileType, convertForm, key }) => {
          let status = 'success';
          return (
            <div className='modal_content-wrapper' key={key}>
              <div className='convert-formate-inner'>
                <div className='file-converting-main'>
                  <div className='file-convert-img'>
                    <FileIcon extension={convertedFileType} />
                  </div>
                  <div className='file-list-inner'>
                    <p>{name}</p>
                    <span>{size}</span>
                    <span
                      className={`errorclass ${status !== strings.SUCCESS && status !== strings.PENDING ? 'error-style' : ''}`}
                    >
                      {status !== strings.SUCCESS && status !== strings.PENDING && (
                        <>
                          <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                          {`${capitalizeFirstLetter(status)}`}
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className='file-actions'>
                  <div className='convert-to-main'>
                    {(status === strings.SUCCESS || status === strings.PENDING) && (
                      <div className='convert-formate'>
                        <span>{convertedFileType}</span>
                        <div className='convert-img'>
                          <img src={right_arrows} alt='' />
                        </div>
                        <div className='convert-option'>
                          <button
                            className='convert-to-btn'
                            onClick={() => {
                              onFileMetadata(convertForm);
                              toggleModalDropdown(key, convertForm);
                            }}
                          >
                            <img src={format_icon} alt='Format Icon' />
                            <span
                              style={{
                                textTransform: modalConvertOptions && modalConvertOptions[key] && 'uppercase'
                              }}
                            >
                              {(modalConvertOptions && modalConvertOptions[key]) || strings.CONVERT_TO}
                            </span>
                            <img src={down_arrows} alt='' />
                          </button>
                          {fileErrors[key] && <p className='error-message'>{strings.NO_ALL_OPTION_SELECTED}</p>}
                        </div>
                      </div>
                    )}
                    {modalOpenDropdowns[key] && (
                      <ConverterDropdown
                        setIsOpen={() => toggleModalDropdown(key)}
                        onSelectConvertTo={(option) => handleSelectModalConvertTo(key, option)}
                        metadata={metadata}
                        isLoading={isLoading}
                      />
                    )}
                    <div className='convert-close'>
                      <button className='close_imgfield' onClick={() => handleDeleteSelectedFiles(key)}>
                        <img src={Cancle_button} alt='' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div style={{ textAlign: 'center' }}>
          <div className='upload_btn-modal' onClick={handleBatchConvert}>
            <span>Convert Files</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalListingComponent;
