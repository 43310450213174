import React, { useState } from 'react';
import './dashboardfooter.scss';
import { fc_footer_logo } from '../../../assets/images/footer';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const DashboardFooter = () => {
  const cookie = new Cookies();
  // eslint-disable-next-line no-unused-vars
  const [lang, setLang] = useState(cookie.get('lang') || 'en');
  return (
    <>
      {/* <!----------- Footer -----------> */}
      <footer className='dashboard-footer-info'>
        <div className='main_container'>
          <div className='inside_footer__content'>
            <div className='footer-widget-1'>
              <div className='widget_media_image'>
                <Link to={`/${lang}/`}>
                  <img src={fc_footer_logo} alt='Files Convert Footer Logo' />
                </Link>
              </div>
              <div className='widget_custom_html'>
                <p>Convert archives, documents, images, videos, audio, websites and more online.</p>
              </div>
            </div>
          </div>
          <div className='inside-site-info'>
            <div className='copyright-bar'>
              <p>
                © { new Date().getFullYear() } <Link to={`/${lang}/files_convert`}>Files Convert. </Link> All rights reserved
              </p>
            </div>
            <div className='footer-bar-list'>
              <ul>
                <li>
                  <Link to={`/${lang}/privacy`}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={`/${lang}/terms`}>Terms of Service</Link>
                </li>
                <li>
                  <Link to={`/${lang}/cookie`}>Cookie Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* <!----------- Footer -----------> */}
    </>
  );
};

export default DashboardFooter;
