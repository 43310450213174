import {
  all_Icon,
  archive,
  cad,
  delete_file_icon,
  documents,
  download_file_icon,
  drawing,
  drive_icon,
  drop_box_image,
  ebook,
  font,
  image,
  link_icon,
  one_drive,
  others,
  presantation,
  spreadsheet,
  vector,
  video
} from '../assets/images/dashboard';
import {
  convert_jpg_icon,
  convert_pdf_icon,
  convert_png_icon,
  convert_world_icon,
  doc_tool_icon,
  image_tool_icon,
  vector_tool_icon,
  video_tool_icon
} from '../assets/images/home';
import { apiKeyDropbox } from '../environment';
import strings from './strings.json';

export const languages = [
  { code: 'cz', name: 'Cesky', alt: 'Cesky', flagCode: 'cz' },
  { code: 'de', name: 'Deutsch', alt: 'Deutsch', flagCode: 'de' },
  { code: 'en', name: 'English', alt: 'ENGLISH', flagCode: 'gb' },
  { code: 'es', name: 'Español', alt: 'Español', flagCode: 'es' },
  {
    code: 'gr',
    name: 'Eλληνικός',

    alt: 'Eλληνικός',
    flagCode: 'gr'
  },
  { code: 'fr', name: 'French', alt: 'FRENCH', flagCode: 'fr' },
  { code: 'hu', name: 'Magyar', alt: 'Magyar', flagCode: 'hu' },
  {
    code: 'fi',
    name: 'Suomalainen',

    alt: 'Suomalainen',
    flagCode: 'fi'
  },
  {
    code: 'ice',
    name: 'Islenskur',

    alt: 'islenskur',
    flagCode: 'is'
  },
  {
    code: 'et',
    name: 'Eesti keel',

    alt: 'Eesti keel',
    flagCode: 'et'
  },
  { code: 'hi', name: 'हिंदी', alt: 'Hindi', flagCode: 'in' },
  {
    code: 'zhh',
    name: 'Cantonese',

    alt: 'Cantonese',
    flagCode: 'cn'
  },
  { code: 'th', name: 'Thai', alt: 'Thai', flagCode: 'th' },
  { code: 'bn', name: 'Bengali', alt: 'Bengali', flagCode: 'bn' },
  { code: 'ms', name: 'Malay', alt: 'Malay', flagCode: 'ms' },
  { code: 'kr', name: '한국어', alt: '한국어', flagCode: 'kr' },
  {
    code: 'hrv',
    name: 'Hrvatski',

    alt: 'Hrvatski',
    flagCode: 'hr'
  },
  {
    code: 'zh-CN',
    name: 'Chinese',

    alt: 'Chinese',
    flagCode: 'cn'
  },
  { code: 'ga', name: 'Gaeilge', alt: 'Gaeilge', flagCode: 'ga' },
  {
    code: 'id',
    name: 'Indonesian',

    alt: 'Indonesian',
    flagCode: 'id'
  },
  {
    code: 'ja',
    name: 'Japanese',

    alt: 'Japanese',
    flagCode: 'jp'
  },
  { code: 'sv', name: 'Svenska', alt: 'Svenska', flagCode: 'sv' },
  {
    code: 'it',
    name: 'Italiano',

    alt: 'Italiano',
    flagCode: 'it'
  },
  {
    code: 'bg',
    name: 'Български',

    alt: 'Български',
    flagCode: 'bg'
  },
  { code: 'sr', name: 'Serbian', alt: 'Serbian', flagCode: 'sr' },
  {
    code: 'uk',
    name: 'Ukrainian',

    alt: 'Ukrainian',
    flagCode: 'ua'
  },
  {
    code: 'heb',
    name: 'Hebrew',

    alt: 'Hebrew',
    flagCode: 'il'
  },
  { code: 'sk', name: 'Slovak', alt: 'Slovak', flagCode: 'sk' },
  { code: 'da', name: 'Danish', alt: 'Danish', flagCode: 'dk' },
  { code: 'ac', name: 'Arabic', alt: 'Arabic', flagCode: 'ae' },
  {
    code: 'nl',
    name: 'Nederlands',

    alt: 'Nederlands',
    flagCode: 'nl'
  },
  { code: 'no', name: 'Norsk', alt: 'Norsk', flagCode: 'no' },
  { code: 'pl', name: 'Polski', alt: 'Polski', flagCode: 'pl' },
  {
    code: 'zh',
    name: 'Mandarin',

    alt: 'Mandarin',
    flagCode: 'cn'
  },
  {
    code: 'pt',
    name: 'Português',

    alt: 'Português',
    flagCode: 'pt'
  },
  {
    code: 'ro',
    name: 'Romanesc',

    alt: 'Romanesc',
    flagCode: 'ro'
  },
  {
    code: 'sl',
    name: 'Slovenscina',

    alt: 'Slovenscina',
    flagCode: 'sl'
  },
  { code: 'tr', name: 'Turkish', alt: 'turkish', flagCode: 'tr' },
  {
    code: 'pt-br',
    name: 'Portuguese (BR)',

    alt: 'Portuguese (BR)',
    flagCode: 'pt'
  }
];

export const fieldsImage = [
  {
    id: 1,
    field: 'All',
    image: all_Icon
  },
  {
    id: 2,
    field: 'vector',
    image: vector
  },
  {
    id: 3,
    field: 'Image',
    image: image
  },
  {
    id: 4,
    field: 'Font',
    image: font
  },
  {
    id: 5,
    field: 'Archive',
    image: archive
  },
  {
    id: 6,
    field: 'Cad',
    image: cad
  },
  {
    id: 7,
    field: 'Document',
    image: documents
  },
  {
    id: 8,
    field: 'Ebook',
    image: ebook
  },
  {
    id: 9,
    field: 'Other',
    image: others
  },
  {
    id: 10,
    field: 'Presentation',
    image: presantation
  },
  {
    id: 11,
    field: 'Spreadsheet',
    image: spreadsheet
  },
  {
    id: 12,
    field: 'Drawing',
    image: drawing
  },
  {
    id: 13,
    field: 'Video',
    image: video
  },
  {
    id: 14,
    field: 'Audio',
    image: video
  }
];

export const toolsData_1 = [
  {
    toolBodyClr: '#6F56EC',
    elementClr: '#9D8CF2',
    icon: image_tool_icon,
    numberOfTools: '13+ tools',
    toolName: 'Image Tools',
    description: 'Solve Your Img Problems',
    fileTypes: [
      '3FR',
      'ARW',
      'AVIF',
      'BMP',
      'CR2',
      'CR3',
      'CRW',
      'DCR',
      'DNG',
      'EPS',
      'ERF',
      'GIF',
      'HEIC',
      'HEIF',
      'ICNS',
      'ICO',
      'JFIF',
      'JPEG',
      'JPG',
      'MOS',
      'MRW',
      'NEF',
      'ODD',
      'ODG',
      'ORF',
      'PEF',
      'PNG',
      'PPM',
      'PS',
      'PSD',
      'RAF',
      'RAW',
      'RW2',
      'TIF',
      'TIFF',
      'WEBP',
      'X3F',
      'XCF',
      'XPS'
    ],
    featuredTool: 'Image Convert'
  },
  {
    toolBodyClr: '#F66213',
    elementClr: '#F8935F',
    icon: doc_tool_icon,
    numberOfTools: '10+ tools',
    toolName: 'Document Tools',
    description: 'Solve Your Doc. Problems',
    fileTypes: [
      'ABW',
      'DJVU',
      'DOC',
      'DOCM',
      'DOCX',
      'DOT',
      'DOTX',
      'HTML',
      'HWP',
      'LWP',
      'MD',
      'ODT',
      'PAGES',
      'PDF',
      'RST',
      'RTF',
      'TEX',
      'TXT',
      'WPD',
      'WPS',
      'ZABW'
    ],
    featuredTool: 'Merge Document'
  },
  {
    toolBodyClr: '#1C67CA',
    elementClr: '#6598DB',
    icon: vector_tool_icon,
    numberOfTools: '8+ tools',
    toolName: 'Vector Tools',
    description: 'Solve Your Vector Problems',
    fileTypes: ['AI', 'CDR', 'CGM', 'EMF', 'SK', 'SK1', 'SVG', 'SVGZ', 'VSD', 'WMF'],
    featuredTool: 'Vector Document'
  },
  {
    toolBodyClr: '#247881',
    elementClr: '#6AA3AA',
    icon: video_tool_icon,
    numberOfTools: '12+ tools',
    toolName: 'Video Tools',
    description: 'Solve Your Video Problems',
    fileTypes: [
      '3G2',
      '3GP',
      '3GPP',
      'AVI',
      'CAVS',
      'DV',
      'DVR',
      'FLV',
      'M2TS',
      'M4V',
      'MKV',
      'MOD',
      'MOV',
      'MP4',
      'MPEG',
      'MPG',
      'MTS',
      'MXF',
      'OGG',
      'RM',
      'RMVB',
      'SWF',
      'TS',
      'VOB',
      'WEBM',
      'WMV',
      'WTV'
    ],
    featuredTool: 'Video Converter'
  }
];
export const mobileOption = [
  {
    id: 1,
    image: download_file_icon,
    name: 'Download'
  },
  {
    id: 2,
    image: delete_file_icon,
    name: 'Delete'
  }
];

export const dashboardItems = [
  {
    id: 1,
    image: one_drive,
    name: 'One Drive'
  },
  {
    id: 2,
    image: drop_box_image,
    name: 'Dropbox'
  },
  {
    id: 3,
    image: link_icon,
    name: 'Get From URL'
  },
  {
    id: 4,
    image: drive_icon,
    name: 'Google Drive'
  }
];

export const categories = {
  archive: ['zip', 'tar', '7z', 'rar', 'targz', 'targz2'],
  document: ['pdf', 'doc', 'docx', 'txt', 'html', 'odt', 'rtf', 'tex', 'md', 'rst'],
  image: ['jpg', 'png', 'gif', 'bmp', 'bmp', 'ps', 'ico', 'odd', 'tiff', 'webp', 'psd', 'eps', 'avif', 'xps', 'icns'],
  audio: [
    'm4a',
    'wma',
    'aac',
    'mp3',
    'flac',
    'wav',
    'aiff',
    'ac3',
    'aifc',
    'aif',
    'caf',
    'au',
    'mab',
    'amr',
    'weba',
    'oga',
    'voc'
  ],
  vector: ['svg', 'wmf', 'emf', 'cgm'],
  spreadsheet: ['xlsx', 'csv', 'ods', 'xls'],
  presentation: ['odp', 'pptx', 'ppt'],
  font: ['eot', 'otf', 'ttf', 'woff', 'woff2'],
  ebook: ['azw3', 'pdb', 'oeb', 'epub', 'mobi', 'irf'],
  cad: ['dxf', 'dwg'],
  video: [
    'mp4',
    'wmv',
    '3gp',
    'avi',
    'mov',
    'fiv',
    'webm',
    '3g2',
    'mkv',
    '3gpp',
    'rmvb',
    'swf',
    'mpeg',
    'm2ts',
    'mod',
    'mts',
    'dv',
    'rm',
    'dvr',
    'cavs',
    'mxf',
    'ogg',
    'm4v',
    'wtv',
    'ts',
    'vob'
  ]
};

export const fileTypeStyles = {
  svg: { color: '#0076CE', dark: '#1B5F92' },
  wmf: { color: '#0FAC8D', dark: '#29695C' },
  emf: { color: '#8E2D8F', dark: '#482748' },
  cgm: { color: '#90BF21', dark: '#6C8F17' },
  xlsx: { color: '#74BE47', dark: '#5A9437' },
  csv: { color: '#295170', dark: '#1D394F' },
  ods: { color: '#8B6AD4', dark: '#6A51A0' },
  xls: { color: '#8AA027', dark: '#62711F' },
  ppt: { color: '#FF7274', dark: '#CE5355' },
  pptx: { color: '#A5356A', dark: '#76264C' },
  odp: { color: '#B58D65', dark: '#8B6B4A' },
  eot: { color: '#9C5F33', dark: '#7B4924' },
  otf: { color: '#895E63', dark: '#473A3B' },
  ttf: { color: '#39385A', dark: '#2B2A44' },
  woff: { color: '#354024', dark: '#28301D' },
  woff2: { color: '#564920', dark: '#383015' },
  azw3: { color: '#017286', dark: '#025463' },
  pdb: { color: '#701D25', dark: '#53151B' },
  oeb: { color: '#3C3982', dark: '#2C2A5C' },
  epub: { color: '#668239', dark: '#4F652D' },
  mobi: { color: '#88661D', dark: '#674E18' },
  irf: { color: '#8C2C6F', dark: '#571B45' },
  dxf: { color: '#1D4D55', dark: '#14383E' },
  dwg: { color: '#522A2E', dark: '#3A1E21' },
  targz: { color: '#3A4E52', dark: '#29383A' },
  tar: { color: '#4D3F40', dark: '#382E2F' },
  rar: { color: '#4C545E', dark: '#373D44' },
  zip: { color: '#4E4135', dark: '#372E25' },
  '7z': { color: '#3C4750', dark: '#2D363D' },
  targz2: { color: '#3E523A', dark: '#313E2E' },
  html: { color: '#68CDDF', dark: '#5AACBB' },
  pdf: { color: '#F04E5F', dark: '#C1404D' },
  txt: { color: '#5C98DC', dark: '#4D7FB8' },
  odt: { color: '#CC8946', dark: '#AE753C' },
  doc: { color: '#43A4F3', dark: '#378ACD' },
  rtf: { color: '#8B995C', dark: '#6D784A' },
  docx: { color: '#7FAD8A', dark: '#507358' },
  tex: { color: '#D3A49E', dark: '#AB837D' },
  md: { color: '#907790', dark: '#735F73' },
  rst: { color: '#7A674D', dark: '#665640' },
  m4a: { color: '#FE9670', dark: '#CF6037' },
  wma: { color: '#BD8750', dark: '#9F662D' },
  aac: { color: '#EDAE70', dark: '#D4904C' },
  mp3: { color: '#D89F03', dark: '#AC7E00' },
  flac: { color: '#82BD9B', dark: '#409664' },
  wav: { color: '#BDDB5C', dark: '#8AA72B' },
  apiff: { color: '#66B970', dark: '#33A741' },
  ac3: { color: '#D3B99E', dark: '#A3896F' },
  aifc: { color: '#878787', dark: '#5F5F5F' },
  aif: { color: '#C0955B', dark: '#A15C00' },
  caf: { color: '#6D9356', dark: '#5C764C' },
  au: { color: '#B6AB70', dark: '#8C8458' },
  mab: { color: '#A9946E', dark: '#80725A' },
  amr: { color: '#A8A47F', dark: '#7B7855' },
  weba: { color: '#738C6E', dark: '#5D7059' },
  oga: { color: '#5D7B92', dark: '#53626C' },
  voc: { color: '#8CA9BF', dark: '#667785' },
  bmp: { color: '#FEC170', dark: '#FF9E1F' },
  gif: { color: '#EA62A3', dark: '#D03A82' },
  ps: { color: '#62C6EA', dark: '#1F9ECC' },
  jpg: { color: '#00BB9D', dark: '#007461' },
  ico: { color: '#5CDB91', dark: '#32AE66' },
  odd: { color: '#71DB5C', dark: '#5BB549' },
  tiff: { color: '#5CA2DB', dark: '#3678AE' },
  webp: { color: '#5C75DB', dark: '#3D58C1' },
  psd: { color: '#27A2FB', dark: '#117DCC' },
  eps: { color: '#D48B2B', dark: '#A15C00' },
  png: { color: '#77D240', dark: '#4DA01B' },
  avif: { color: '#DBC85C', dark: '#BAA31E' },
  xps: { color: '#D29F40', dark: '#B78322' },
  icns: { color: '#CBBA01', dark: '#9C8F00' },
  mp4: { color: '#738369', dark: '#5B6853' },
  wmv: { color: '#7E5054', dark: '#624244' },
  '3gp': { color: '#899AAD', dark: '#788493' },
  avi: { color: '#BCB36E', dark: '#9D9660' },
  mov: { color: '#475561', dark: '#343E46' },
  fiv: { color: '#5F654D', dark: '#4D523F' },
  webm: { color: '#007461', dark: '#005346' },
  '3g2': { color: '#85514A', dark: '#5F3732' },
  mkv: { color: '#5C325C', dark: '#482748' },
  '3gpp': { color: '#E29FC2', dark: '#A1728B' },
  rmvp: { color: '#63525B', dark: '#4D3E46' },
  swf: { color: '#798069', dark: '#5D644D' },
  mpeg: { color: '#9C5555', dark: '#723F3F' },
  m2ts: { color: '#AFB98E', dark: '#7C8465' },
  mod: { color: '#B46A6A', dark: '#805151' },
  mts: { color: '#753F3F', dark: '#562C2C' },
  dv: { color: '#57844E', dark: '#41633A' },
  rm: { color: '#753F61', dark: '#532F46' },
  dvr: { color: '#B24C4C', dark: '#893D3D' },
  cavs: { color: '#7C816F', dark: '#4B4F40' },
  mxf: { color: '#7CA17F', dark: '#576F58' },
  ogg: { color: '#8A5E3E', dark: '#65452F' },
  m4v: { color: '#B09886', dark: '#8B796C' },
  wtv: { color: '#5F5F9E', dark: '#3D3D56' },
  ts: { color: '#41416C', dark: '#323252' },
  vob: { color: '#425272', dark: '#33415B' },
  ogv: { color: '#EB7C37', dark: '#B65D26' },
  opus: { color: '#CD03FF', dark: '#9C00C3' },
  word: { color: '#00acea', dark: '#0076ce' },
  default: { color: '#808080', dark: '#000' }
};

export const convertAllType = [
  {
    id: 1,
    title: 'Convert PDF to Editable Word File',
    source: 'pdf',
    target: 'word',
    sourceImage: convert_pdf_icon,
    targetImage: convert_world_icon
  },
  {
    id: 2,
    title: 'Convert Word File to Editable PDF',
    source: 'word',
    target: 'pdf',
    sourceImage: convert_world_icon,
    targetImage: convert_pdf_icon
  },
  {
    id: 3,
    title: 'Convert JPG to Editable PDF',
    source: 'jpg',
    target: 'pdf',
    sourceImage: convert_jpg_icon,
    targetImage: convert_pdf_icon
  },
  {
    id: 4,
    title: 'Convert PDF to High Quality JPG',
    source: 'pdf',
    target: 'jpg',
    sourceImage: convert_pdf_icon,
    targetImage: convert_jpg_icon
  },
  {
    id: 5,
    title: 'Convert JPG to PNG',
    source: 'jpg',
    target: 'png',
    sourceImage: convert_jpg_icon,
    targetImage: convert_png_icon
  }
];

export const country = 'India';
export const currency = 'eur';
export const validEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
export const paymentElementOptions = {
  layout: 'tabs',
  terms: {
    card: 'never',
    applePay: 'never',
    googlePay: 'never',
    cashapp: 'never',
    auBecsDebit: 'never',
    bancontact: 'never',
    ideal: 'never',
    paypal: 'never',
    sepaDebit: 'never',
    sofort: 'never',
    usBankAccount: 'never'
  }
};

export const appearance = {
  theme: 'stripe'
};

export const stripeOptions = {
  All: [
    'EMF',
    'WMF',
    'BPM',
    'EPS',
    'GIF',
    'ICO',
    'JPG',
    'ODD',
    'PNG',
    'PS',
    'PSD',
    'TIFF',
    'AAC',
    'AC3',
    'AIF',
    'AIFC',
    'AIFF',
    'AIMR',
    'DWG',
    'DXF',
    'ABW',
    'DJVU',
    'DOC',
    'DOCM',
    'HTML',
    'HWP',
    'AZM',
    'AZW3',
    'CBR',
    'LIT',
    'OGV',
    'OPUS',
    'DPS',
    'KEY',
    'AI',
    'CDR'
  ],
  vector: ['2.1', '2.2', '2.3', '2.4', '2.1', '2.2', '2.3', '2.4', '2.5'],
  Image: ['3.1', '4.3', '3.2'],
  Font: ['EMF'],
  Archive: ['BPM'],
  Cad: ['1.1'],
  Document: ['AZM'],
  Ebook: ['TIFF'],
  Other: ['ICO'],
  Presentation: ['ODD'],
  Spreadsheet: ['AIF'],
  Drawing: ['DXF'],
  Video: ['DOC']
};

export const validateField = (errors, fieldName, value, errorMessage) => {
  if (value.trim() === '') {
    errors[fieldName] = errorMessage;
  }
};

// With Token not accessible route
export const publicRoute = [
  'contact',
  'pricing',
  'unsubscribe',
  'login',
  'reset-password',
  'convert',
  'privacy',
  'terms',
  'cookie',
  'payment'
];
// Without Token not accessible route
export const privetRoute = ['dashboard', 'account'];

export function formatDate(timestamp, month) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0'); 
  const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');  
  const year = date.getFullYear();
  if (month) {
    return `${day}.${monthFormatted}.${year}`;  
  } else {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });  
  }
}

export const maxEmailLength = 254;
export const maxPasswordLength = 25;

export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes === undefined || sizeInBytes === null) {
    return "-";
  }
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} ${strings.BYTES}`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} ${strings.KB}`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)}  ${strings.MB}`;
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)}  ${strings.GB}`;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const dropboxExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.jpg',
  '.png',
  '.json',
  '.js',
  '.mp3',
  '.mp4',
  '.dxf',
  '.ppt',
  '.webp',
  '.svg'
];
export const APP_KEY_DROPBOX = apiKeyDropbox?.replace(/['",]/g, '');

export const redirectUri = 'https://stage.files-convert.com/en';

export const validateSpecialChar = /^[a-zA-Z0-9\s]*$/;

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
