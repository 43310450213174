import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import paymentIntentSlice from '../slice/paymentIntentSlice';
import createCustomerSubscriptionSlice from '../slice/createCustomerSubscriptionSlice';
import getPriceSlice from '../slice/getPriceSlice';
import getAuthenticateSlice from '../slice/getAuthenticateSlice';
import uploadFileSlice from '../slice/uploadFileSlice';
import urlValidationSlice from '../slice/urlValidationSlice';
import checkSettingBanIpSlice from '../slice/checkSettingBanIpSlice';
import iPQualityScoreSlice from '../slice/iPQualityScore';
import getIPIfySlice from '../slice/getIPIfySlice';
import settingBanIpSlice from '../slice/settingBanIpSlice';
import userListingSlice from '../slice/userListingSlice';
import fileMetadataSlice from '../slice/fileMetadataSlice';
import removeFileSlice from '../slice/removeFileSlice';
import reconvertFilesSlice from '../slice/reconvertFilesSlice';
import postUploadFileSlice from '../slice/postUploadFileSlice';
import getSendDetailsSlice from '../slice/ipLocationDataSlice';
import notificationListSlice from '../slice/notificationListSlice';
import subscriptionListSlice from '../slice/subscriptionListSlice'; 
import getAllPriceSlice from "../slice/getAllPriceSlice"
import productListSlice from "../slice/productListSlice"
import retryUploadFileSlice  from '../slice/retryUploadFileSlice '; 
import conversionFileId from "../slice/conversionFileId"
const store = configureStore({
  reducer: {
    paymentIntent: paymentIntentSlice,
    createCustomerSubscription: createCustomerSubscriptionSlice,
    getPriceData: getPriceSlice,
    authenticate: getAuthenticateSlice,
    uploadFile: uploadFileSlice,
    urlValidation: urlValidationSlice,
    checkSettingBanIp: checkSettingBanIpSlice,
    iPQualityScore: iPQualityScoreSlice,
    getIPIfy: getIPIfySlice,
    settingBanIp: settingBanIpSlice,
    files: userListingSlice,
    metaFiles: fileMetadataSlice,
    removeFile: removeFileSlice,
    reConvertFiles: reconvertFilesSlice,
    postUploadFiles: postUploadFileSlice,
    getIPLocationData: getSendDetailsSlice,
    notificationList: notificationListSlice,
    subscriptionData: subscriptionListSlice,
    getAllPriceData: getAllPriceSlice,
    getProductListData:productListSlice,
    retryFile:retryUploadFileSlice,
    conversionFileId:conversionFileId
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store;
