import React from 'react';
import { Spin } from 'antd';
import Modal from '../../common/modal/modal';
import { FileConvert } from '../../partials/homeComponent';
import './postUploadModal.scss';
const PostUploadModal = (props) => {
  const { visible, onClose, isLoading } = props;
  return (
    <Modal
      isOpen={visible}
      onOk={() => onClose(false)}
      onClose={() => onClose(false)}
      maskClosable={false}
      closeIcon={false}
      className='file-convert-modal'
    >
      <Spin spinning={isLoading}>
        <FileConvert setIsUploadModal={onClose} isUpdateTitle={true} />
      </Spin>
    </Modal>
  );
};

export default PostUploadModal;
