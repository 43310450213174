import { Form, Input, Spin } from 'antd';
import './resetModal.scss';
import { Home } from '../../../pages';
import strings from '../../../utils/strings.json';
import { maxEmailLength } from '../../../utils/commonUtils';
import Modal from '../../common/modal/modal';

const ResetModal = (props) => {
  const {
    handleResetPassword,
    handleValuesChange,
    emailError,
    resetPasswordError,
    isResetOpen,
    handleClose,
    form,
    isLoading
  } = props;

  return (
    <div>
      <Home />
      <Modal
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        isOpen={isResetOpen}
        onClose={handleClose}
        className='reset-info-wrap'
      >
        <Spin spinning={isLoading}>
          <div className='reset-modal-section'>
            <div className='reset-modal-title'>
              <span>{strings.EMAIL_ADDRESS}</span>
            </div>
            <div className='wrap-top-modal'>
              <Form
                form={form}
                onFinish={handleResetPassword}
                initialValues={{ email: '', password: '' }}
                onValuesChange={handleValuesChange}
              >
                <div className='body-modal'>
                  <div className='modal-reset-form'>
                    <div className='mb'>
                      <Form.Item
                         label={
                          <span>
                            {strings.EMAIL_ADDRESS} <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        name='email'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          className={`${emailError || resetPasswordError ? 'errorField' : ''} payment-inputs`}
                          name='email'
                          placeholder='hello@mail.com'
                          maxLength={maxEmailLength}
                        />
                      </Form.Item>
                      {emailError && <p style={{ margin: '0', color: '#ff4d4f' }}>{emailError}</p>}
                      {!emailError && resetPasswordError && (
                        <p style={{ margin: '0', color: '#ff4d4f', marginTop: 10 }}>{resetPasswordError}</p>
                      )}
                    </div>
                  </div>
                  <div className='submit-btn-modal'>
                    <button className='hl_cta_wrap' disabled={isLoading}>
                      {strings.SET_NEW_PASSWORD}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default ResetModal;
