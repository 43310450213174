import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const readNotification = createAsyncThunk('readNotification', async (id) => {
  try {
    const response = await AxiosInstance.put(`/notification/readNotification`,id);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response?.data?.message || error.response?.data?.detail;
    } else {
      throw error;
    }
  }
});

const readNotificationSlice = createSlice({
  name: 'readNotification',
  initialState: {
    isLoading: false,
    readNotification: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(readNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.readNotification = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action?.error?.message || action?.error?.detail;
      });
  }
});

export default readNotificationSlice.reducer;
