import React from 'react';
import { down_arrows } from '../../../../assets/images/dashboard';
import { convert_arrow_icon, convert_right } from '../../../../assets/images/home';
import { convertAllType } from '../../../../utils/commonUtils';
import ConvertDropbox from '../../homeComponent/convertDropbox/convertDropbox';
import { ConverterDropdown } from '../../homeComponent/filesConvert/converterDropdown';
import './convertAllType.scss';
import { useSelector } from 'react-redux';

const ConvertAllTypeComponent = ({
  onFileMetadata,
  metadata,
  toggleDropdown,
  handleSelectConvertTo,
  openDropdowns,
  target,
  source,
  handleConvert
}) => {
  const metaData = useSelector((state) => state.metaFiles);

  return (
    <>
      {/* <!------------------- how Work Section Start -------------> */}
      <div className='convert-info-wrap' id='conversion-types'>
        <section className='tool-work-section blue-design-pattern pt-100'>
          <div className='main_container'>
            <div className='common-title'>
              <span>- Tools</span>
              <h2>Соnvеrt аll tуреѕ of files</h2>
            </div>
            <div className='tool-work-box-wrap'>
              {convertAllType.map((x) => (
                <div className='tool-work-box' key={x?.id}>
                  <div className='tool-box-head-content'>
                    <h4>{x.title}</h4>
                  </div>
                  <div className='tool-box-icon-wrap'>
                    <div className='tool-box-icon-info tool-box-icon-text'>
                      <img src={x.sourceImage} alt='' className='imgs' />
                      <span className='tool-span'>{x.source}</span>
                    </div>
                    <div className='tool-box-icon-info'>
                      <img src={convert_arrow_icon} alt='' />
                    </div>
                    <div className='tool-box-icon-info tool-box-icon-text'>
                      <img src={x.targetImage} alt='' className='imgs' />
                      <span className='tool-span'>{x.target}</span>
                    </div>
                  </div>
                  <div className='tool-box-button'>
                    <button onClick={() => handleConvert(x.source, x.target)}>
                      Convert Now
                      <img src={convert_right} alt='' />
                    </button>
                  </div>
                </div>
              ))}
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Custom Conversion Type</h4>
                </div>
                <div className='tool-box-icon-wrap' style={{ padding: '49px 12px', gap: 15 }}>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <button
                      className='drop-button'
                      onClick={() => {
                        onFileMetadata();
                        toggleDropdown(1);
                      }}
                    >
                      {source}
                      <img src={down_arrows} alt='' />
                    </button>
                    {openDropdowns[1] && (
                      <ConverterDropdown
                        setIsOpen={() => toggleDropdown(1)}
                        onSelectConvertTo={(option) => handleSelectConvertTo(1, option)}
                        metadata={metadata}
                        isLoading={metaData?.isLoading}
                      />
                    )}
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <button
                      className='drop-button'
                      onClick={() => {
                        onFileMetadata(source);
                        toggleDropdown(2);
                      }}
                    >
                      {target}
                      <img src={down_arrows} alt='' />
                    </button>
                    {openDropdowns[2] && (
                      <ConverterDropdown
                        setIsOpen={() => toggleDropdown(2)}
                        onSelectConvertTo={(option) => handleSelectConvertTo(2, option)}
                        metadata={metadata}
                        isLoading={metaData?.isLoading}
                      />
                    )}
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button onClick={() => handleConvert(source, target)}>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ConvertDropbox />
        </section>
      
      </div>
      {/* <!------------------- how Work Section End -------------> */}
    </>
  );
};

export default ConvertAllTypeComponent;
