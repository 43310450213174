import React, { useEffect } from 'react';
import './contact.scss';
import { contact_us } from '../../assets/images/contact';
import { scrollToTop } from '../../utils/commonUtils';
const ContactComponent = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <section className='contact-us'>
        <div className='main_container'>
          <div className='contact-main-section'>
            <div className='contact-info'>
              <div className='contact-wrapper'>
                <div className='contact-left-content'>
                  <h2>Contact Us</h2>
                  <span>Email us at support@files-convert.com</span>
                  <p>
                    Please contact us via email at{' '}
                    <a href='mailto:support@files-convert.com'>support@files-convert.com</a>, and our customer service
                    team will do their best to reply to you as soon as possible upon receiving your message.
                  </p>
                </div>
                <div className='contact-right-content'>
                  <div className='contact-image'>
                    <img src={contact_us} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComponent;
