import React, { useEffect, useState } from 'react';
import SubscriptionListPage from './subscription';
import { Cookies, useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { subscriptionList, unsubscribe } from '../../../redux/slice';
import Notify from '../../../components/common/notify';
import strings from '../../../utils/strings.json';
import SubscriptionModal from '../../../components/modals/subscriptionModal/SubscriptionModal';
const SubscriptionList = ({ callUnSubscribe, callSubscriptionList, authData, subscriptionData, productList }) => {
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [, , removeToken] = useCookies(['token']);
  const [, , removeRole] = useCookies(['role']);
  const { email } = authData?.data || {};

  useEffect(() => {
    callSubscriptionList();
  }, []);

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const unsubscribeData = {
        email: email.trim().toLowerCase()
      };
      const result = await callUnSubscribe(unsubscribeData);
      if (result?.type === 'unsubscribe/fulfilled') {
        Notify('success', result?.payload?.message || strings.UNSUBSCRIBED_SUCCESSFULLY, '');
        setTimeout(() => {
          removeToken(['token']);
          removeRole(['role']);
          const cookieKeys = Array.isArray(cookie.getAll()) ? cookie.getAll() : Object.keys(cookie.getAll());
          cookieKeys.forEach((key) => {
            cookie.remove(key, { path: '/' });
          });
          navigate('/');
          window.location.reload('/');
        }, 5000);
      } else {
        Notify('error', '', result?.error?.message || strings.UNSUBSCRIBE_FAILED);
      }
    } catch (error) {
      Notify(strings.UNSUBSCRIBE_FAILED);
    } finally {
      setIsLoading(false);
      setIsSubscriptionModalOpen(false);
    }
  };

  return (
    <>
      <SubscriptionListPage
        setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
        subscriptionData={subscriptionData?.data}
        productList={productList}
      />
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          visible={isSubscriptionModalOpen}
          handleSubscribe={handleSubscribe}
          onClose={() => setIsSubscriptionModalOpen(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state?.authenticate?.getAuthenticateData,
    subscriptionData: state?.subscriptionData?.subscriptionList,
    productList: state.getProductListData?.productList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callSubscriptionList: () => dispatch(subscriptionList()),
    callUnSubscribe: (data) => dispatch(unsubscribe(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
