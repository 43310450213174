import React, { useEffect, useState } from 'react';
import './footer.scss';
import { fc_footer_logo } from '../../../assets/images/footer';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
const Footer = () => {
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('lang') || 'en');
  const [scrollTarget, setScrollTarget] = useState(null);
  const isConvertPage = window.location.pathname.split('/')[2] === 'convert';

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollTarget) {
      scrollToSection(scrollTarget);
      setScrollTarget(null);
    }
  }, [location, scrollTarget]);
  return (
    <>
      {/* <!----------- Footer -----------> */}
      <footer className='site__footer'>
        <div className='main_container'>
          <div className='inside_footer__content'>
            <div className='footer-widget-1'>
              <div className='widget_media_image'>
                <Link to={`/${lang}/`}>
                  <img src={fc_footer_logo} alt='Files Convert Footer Logo' />
                </Link>
              </div>
              <div className='widget_custom_html'>
                <p>Convert archives, documents, images, videos, audio, websites and more online.</p>
              
              </div>
            </div>
            <div className='footer-widget-2'>
              <h2 className='resources-widget-title'>Navigation</h2>
              <div className='menu-footer-resources-menu'>
                <ul className='menu-item-type-post_type'>
                  <li>
                    <Link to={`/${lang}/`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${lang}${isConvertPage ? '/convert' : '/'}`}
                      onClick={() => setScrollTarget('conversion-types')}
                    >
                      Conversion Types
                    </Link>
                  </li>
                  {!isConvertPage && (
                    <li className='link'>
                      <Link to={`/${lang}/pricing`}>Pricing</Link>
                    </li>
                  )}
                  <li>
                    <Link to={`/${lang}/contact`}>Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='footer-widget-3'>
              <h2 className='resources-widget-title'>Account</h2>
              <div className='menu-footer-resources-menu'>
                <ul className='menu-item-type-post_type'>
                  <li>
                    <Link to={`/`} onClick={() => scrollToSection('file_convert')}>
                      Sign Up
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/login`}>Sign In</Link>
                  </li>
                  {!isConvertPage && (
                    <>
                      {/* <li>
                        <Link to={`/${lang}/convert`}>Files</Link>
                      </li> */}
                      <li>
                        <Link to={`/${lang}/unsubscribe`}>Unsubscribe</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className='footer-widget-4'>
              <h2 className='resources-widget-title'>Policies</h2>
              <div className='menu-footer-resources-menu'>
                <ul className='menu-item-type-post_type'>
                  <li>
                    <Link to={`/${lang}/privacy`}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/terms`}>Terms of Service</Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/cookie`}>Cookie Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='inside-site-info'>
            <div className='copyright-bar'>
              <p>
                ©{ new Date().getFullYear() } <Link to={`/${lang}/files_convert`}>Files Convert.</Link>
                All rights reserved
              </p>
            </div>
            {/* <div className="footer-bar">
              <img src={footer_payment_icon} alt="Footer Payment Icon" />
            </div> */}
          </div>
        </div>
      </footer>

      {/* <!----------- Footer -----------> */}
    </>
  );
};

export default Footer;
