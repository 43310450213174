import React from 'react';
import strings from '../../../../../utils/strings.json';
import DropBoxComponent from './dropBox';
import { isValidFilesize } from '../helper';

const DropBox = (props) => {
  const { setUploadInProgress, setSelectedFiles, setLimitExtendMessage,uploadFileHandler,onDismiss } = props;

  const getDropboxSelectedFile = async (file) => {
    await uploadFileHandler({
      file,
      downloadLink: file.link,
    });
  };

  const handleSuccess = async (files) => {
  const newFiles= isValidFilesize(files);
    setSelectedFiles((prevFiles) => {
      const totalFiles = prevFiles.length + newFiles.length;
      if (totalFiles > 20) {
        setLimitExtendMessage(strings.FILE_LIMIT_ERROR_MESSSGE);
        const availableSlots = 20 - prevFiles.length;
        return [...prevFiles, ...newFiles.slice(0, availableSlots)];
      }

      setLimitExtendMessage('');
      return [...prevFiles, ...newFiles];
    });
    if (files.length === 0) {
      return;
    }
  
    setUploadInProgress(true);
    for (const file of files) {
      await getDropboxSelectedFile(file);
    }
    setUploadInProgress(false);
  };

  return <DropBoxComponent handleSuccess={handleSuccess} onDismiss={onDismiss} />;
};

export default DropBox;
