import React from 'react';

const SubscriptionListPage = ({ setIsSubscriptionModalOpen, subscriptionData, productList }) => {
  const cur = subscriptionData?.currency;
  const trialProduct = productList?.data?.find((item) => item.title === 'trial');
  const findPrice = trialProduct?.currencyOptions?.find((item) => item.parameter === cur);
  const subscriptionStatusMap = {
    active: 'Active',
    canceled: 'Cancel',
    past_due: 'Past Due'
  };
  return (
    <>
      <div className='wpb_content_subs account_bg_wrap'>
        <div className='account_title'>
          <h2>Subscription</h2>
        </div>
        <div className='subscriptions_table'>
          <table>
            <thead>
              <tr>
                <th>Frequency</th>
                <th>Next Invoice</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-title='Frequency'>
                  <span>
                    <span>{subscriptionData?.frequency === 'monthly' ? 'Monthly' : 'N/A'}</span>
                    {subscriptionData?.trial && <button class='tracking_btn false'>Trial</button>}
                  </span>
                </td>
                <td data-title='Next Invoice'>
                  {subscriptionData?.nextInvoice} for {findPrice?.symbol}
                  {subscriptionData?.amount}
                </td>
                <td data-title='Status'>
                  <span className={subscriptionData?.status === 'active' ? 'green-bg' : 'red-bg'}>
                    {subscriptionStatusMap[subscriptionData?.status] || 'N/A'}
                  </span>
                </td>
                <td class='cancel_subscription'>
                  <button class='cancel_btn' onClick={() => setIsSubscriptionModalOpen(true)}>
                    Cancel Subscription
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SubscriptionListPage;
