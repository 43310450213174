import React, { useEffect } from 'react';
import { hero_banner_pattern } from '../../assets/images/home';
import FileConvert from '../../components/partials/homeComponent/filesConvert';
import FilesConvertInformativeComponent from '../../components/partials/homeComponent/filesConvertInformative/filesConvertInformative';
import EasySteps from '../../components/partials/homeComponent/easySteps';
import ConvertAllType from '../../components/partials/convertComponent/convertAllType';
import { scrollToTop } from '../../utils/commonUtils';

const ConvertFileComponent = () => {

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {/* <!------------------ Banner Section Start -----------> */}
      <div className='banner-bg-img'>
        <img className='destop-img' src={hero_banner_pattern} alt='Hero Banner' />
      </div>
      <FileConvert />
      {/* <!------------------ Banner Section End -----------> */}
      <ConvertAllType />
      <EasySteps />
      <FilesConvertInformativeComponent />
    </>
  );
};

export default ConvertFileComponent;
