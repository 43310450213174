import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Menu, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useSession } from '../../../../context/SessionContext';
import { socket_listen_key, stripeLoadScript } from '../../../../environment';
import { appearance, currency, scrollToTop } from '../../../../utils/commonUtils';
import strings from '../../../../utils/strings.json';
import Notify from '../../../common/notify';
import PaymentModal from '../../../modals/paymentModal/paymentModal';
import ThankyouModal from '../../../modals/thankyouModal/thankyouModal';
import FileConvertComponent from './filesConvert';
import {
  postUploadFile,
  saveFiles,
  fetchFileMetadata,
  downloadFile,
  uploadFile,
  saveAnalytics,
  paymentIntent,
  convertFile,
  checkSettingBanIp,
  appendFiles,
  multipleDownload
} from '../../../../redux/slice';
import FileIcon from '../../../fileIcon/fileIcon';
import { fileConvertTypes, triggerDownload } from './helper';
import PostConvertModal from '../../../modals/postConvertModal/postConvertModal';
import { singleFileDownload } from '../../../../redux/slice/singleFileDownloadSlice';
import { addFileId } from '../../../../redux/slice/conversionFileId';

const socket = io(socket_listen_key);

const stripePromise = loadStripe(stripeLoadScript);
const FileConvert = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [percent, setPercent] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState('');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [uploadInProgress, setUploadInProgress] = useState(false); // New flag to track upload state
  const [validationErrors, setValidationErrors] = useState({}); // State for validation errors
  const [limitExtendMessage, setLimitExtendMessage] = useState('');
  const [source, setSource] = useState('doc');
  const [target, setTarget] = useState('pdf');
  const dispatch = useDispatch();
  const {
    callpaymentIntent,
    callUploadFile,
    callSaveAnalytics,
    callConvertFiles,
    callFetchFileMetadata,
    callCheckSettingBanIp,
    callPostUpload,
    callSaveFile,
    setIsUploadModal,
    callMultipleDownload,
    callSingleFileDownload,
    isUpdateTitle
  } = props;
  const cookies = new Cookies();
  const banIP = cookies.get('banned');
  const options = {
    clientSecret,
    appearance,
    locale: 'en'
  };
  const { sessionId } = useSession();
  const [conversionErrors, setConversionErrors] = useState({});
  const token = cookies.get('token');
  const [isShowThankyouPopup, setShowThankyouPopup] = useState(false);
  const navigate = useNavigate();
  const lang = cookies.get('lang') || 'en';
  const [searchParams] = useSearchParams();
  const [navigateUrl, setNavigateUrl] = useState(null); // Track the URL for navigation
  const [metadata, setMetadata] = useState([]);
  const [isShowConvertPopup, setIsShowConvertPopup] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const searchNumber = Object.fromEntries([...searchParams]);
  const [conversionFileID, setConversionFileID] = useState([]);
  const keys = Object.keys(searchNumber);
  const ipData = useSelector((state) => state?.getIPIfy?.getIPIfyData);
  const userId = useSelector((state) => state?.createCustomerSubscription?.createCustomerSubscriptionData?.data?.userId ||{});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sourceMenu = (
    <Menu onClick={({ key }) => handleMenuClick(key, 'source')}>
      {fileConvertTypes.map(({ key, extension, label }) => (
        <Menu.Item key={key}>
          <div className='file-icons-inner'>
            <FileIcon extension={extension} />
            <span>{label}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const targetMenu = (
    <Menu onClick={({ key }) => handleMenuClick(key, 'target')}>
      {fileConvertTypes.map(({ key, extension, label }) => (
        <Menu.Item key={key}>
          <div className='file-icons-inner'>
            <FileIcon extension={extension} />
            <span>{label}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  useEffect(() => {
    if (conversionFileID.length > 0) {
      // Dispatch to Redux store
      dispatch(addFileId(conversionFileID));
    }
  }, [conversionFileID, dispatch]);
  useEffect(() => {
    if (params.size > 0) {
      const convertValue = params.get('convert');
      const [first, second] = convertValue ? convertValue.split('-to-') : [];
      if (first && second) {
        setSource(first);
        setTarget(second);
      } else {
        setSource('doc');
        setTarget('pdf');
      }
    }
  }, [location, params]);

  useEffect(() => {
    if (userId) {
      socket.emit('join', userId);
    }

    return () => {
      if (userId) {
        socket.emit('leave', userId);
      }
    };
  }, [userId]);

  useEffect(() => {
    if (token) {
      const notificationData = (data) => {
        console.log('notification', data);
      };
      socket.on('notification', notificationData);
      const statusUpdateHandler = (data) => {
        console.log('status-update', data);
        if (data?.data) {
          if (data?.data?.conversionStatus !== 'INPROGRESS') {
            dispatch(appendFiles(data?.data));
          }
        }
      };

      socket.on('status-update', statusUpdateHandler);

      return () => {
        socket.off('notification', notificationData);
        socket.off('status-update', statusUpdateHandler);
      };
    }
  }, []);

  useEffect(() => {
    let progressInterval;

    const handleProgressAndApiCall = async () => {
      progressInterval = setInterval(() => {
        setPercent((prevPercent) => {
          const newPercent = prevPercent + 12;
          if (newPercent >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return Math.min(newPercent, 100);
        });
      }, 1000);

      await new Promise((resolve) => setTimeout(resolve, 8000));
      clearInterval(progressInterval);
      setMove(false);

      try {
        const intentData = {
          amount: 50,
          currency: currency
        };
        const paymentResult = await callpaymentIntent(intentData);
        const { type, payload, error } = paymentResult || {};
        const currentPath = window.location.pathname;
        const checkIp = await callCheckSettingBanIp({ ipAddress: ipData?.ip });

        if (currentPath.includes('convert') && !keys.includes('off') && !keys.includes('clk')) {
          const updatedPath = currentPath.replace('/convert', '');
          if (currentPath !== updatedPath) {
            window.history.pushState(null, '', updatedPath);
          }
        }

        if (type === 'paymentIntent/fulfilled') {
          setCustomer(payload.data.customer);
          setClientSecret(payload.data.client_secret);
          setCheckoutModalOpen(true);

          if (keys.includes('off')) {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete('off');

            if ([...newParams].length === 0) {
              setNavigateUrl('/en/convert');
            } else {
              setNavigateUrl(`/${lang}/convert?${newParams.toString()}`);
            }
          } else if (banIP || !checkIp?.payload?.data) {
            setCheckoutModalOpen(true);
            const updatedPath = currentPath.replace('/convert', '');
            if (currentPath !== updatedPath) {
              window.history.pushState(null, '', updatedPath);
            }
          }
        } else {
          Notify('error', error.message, '');
        }
      } catch (err) {
        Notify('error', 'An unexpected error occurred. Please try again.', '');
      } finally {
        setMove(false);
        setPercent(0);
      }
    };

    if (move) {
      handleProgressAndApiCall();
    }

    return () => clearInterval(progressInterval);
  }, [move]);

  useEffect(() => {
    if (navigateUrl) {
      navigate(navigateUrl, { replace: true });
    }
  }, [navigateUrl, navigate]);

  // Payment and register function

  const dashRegister = async () => {
    setLoading(true);

    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));
      // eslint-disable-next-line no-unused-vars
      const requestData = {
        files: newFiles
      };
      try {
        setIsShowConvertPopup(true);
        let result = await callSaveFile(requestData);
        if (result?.type === 'saveFiles/fulfilled') {
          // setSelectedFiles([]);
          // setIsShowConvertPopup(true);

          const convertFilesResult = await callConvertFiles({ type: 'dashboard' });
          const { error: validationError, type: validationType, payload: convertPayload } = convertFilesResult || {};
          setLoading(true);
          if (validationType === 'convertFile/fulfilled') {
            Notify('success', convertPayload.message, '');
          } else {
            Notify('error', validationError.message, '');
          }
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));
      const requestData = {
        sessionId: sessionId,
        files: newFiles,
        // ipAddress: ipData?.ip
      };
      try {
        let result = await callSaveAnalytics(requestData);
        if (result?.type === 'saveAnalytics/fulfilled') {
          if (result?.payload?.data?.success === false) {
            const errors = {};
            const filesToProcess = [...selectedFiles];
            result?.payload?.data.notAllowed.forEach(({ fileName, message }) => {
              const fileIndex = filesToProcess.findIndex((file) => file.file.name === fileName);
              if (fileIndex !== -1) {
                const fileWithError = filesToProcess[fileIndex];
                errors[fileWithError.id] = message;

                filesToProcess.splice(fileIndex, 1);
              }
            });
            setConversionErrors(errors);
          } else {
            if (!token) {
              params.delete('convert');
              navigate(`?${params.toString()}`);
              setMove(true);
              const element = document.getElementById('file_convert');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }
        } else {
          const errors = {};

          result?.payload?.data.notAllowed.forEach(({ fileName, fileExtension, fileConvertTo, message }) => {
            const fileWithError = selectedFiles.find(
              (file) =>
                file.file.name === fileName &&
                file.file.extension === fileExtension &&
                file.convertedTo?.toLowerCase() === fileConvertTo?.toLowerCase()
            );

            if (fileWithError) {
              errors[fileWithError.id] = message;
            }
          });
          setConversionErrors(errors);
          Notify('error', result?.error.message, '');
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };

  // Create Common function for upload file to BE

  const uploadFileCommon = async (file, additionalData = {}) => {
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (source && fileExtension !== source.toLowerCase() && params.size > 0) {
        return { success: false, error: `File should be ${source}` };
      }
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('ipAddress', ipData?.ip);
    if (!token) {
      formData.append('sessionId', sessionId);
    }
    Object.entries(additionalData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const result = token ? await callPostUpload(formData) : await callUploadFile(formData);
      const { type, payload, error } = result || {};

      if (type?.endsWith('/fulfilled') && payload?.success) {
        return {
          success: true,
          data: {
            id: payload.data._id,
            file: {
              name: payload.data.fileOriginalName,
              size: payload.data.size
            },
            convertedFrom: payload.data.convertedFrom,
            fileConvertId: payload.data._id,
            status: strings.SUCCESS,
            percent: 100,
            ...(source && params.size > 0 && { convertedTo: target?.toLowerCase() })
          }
        };
      } else {
        return { success: false, error: error?.message || 'File upload failed' };
      }
    } catch (error) {
      console.error('Error in uploadFile:', error.message);
      return { success: false, error: error.message };
    }
  };

  //Delete file from the list

  const handleDeleteFile = (fileId) => {
    setLimitExtendMessage('');
    setSelectedFiles((prevFiles) => prevFiles.filter((fileObj) => fileObj.id !== fileId));
  };

  // Convert option select validation

  const validateConvertToSelection = () => {
    setLoading(false);

    const errors = {};
    selectedFiles.forEach((file) => {
      if (file.status === strings.SUCCESS && !file.convertedTo) {
        errors[file.id] = 'Required';
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Convert option selection

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fileId];
      return newErrors;
    });
    const updatedFiles = selectedFiles.map((file) => {
      if (file.id === fileId) {
        return { ...file, convertedTo: convertToOption?.toLowerCase() };
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  const handleConvertFiles = async () => {
    setLoading(true);
    try {
      if (selectedFiles.length > 1) {
        socket.emit('join', userId);
        const result = await callMultipleDownload({});
        const { type, payload } = result;
        if (type === 'multipleDownload/fulfilled') {
          if (payload.success) {
            triggerDownload(payload.data.fileUrl);
            Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
            setIsDownload(true);
          } else {
            Notify('success', 'We will notify after converting in zip', '');
            socket.off('zip-status');
            socket.on('zip-status', (data) => {
              if (!data.data.processing) {
                if (data.data.status === 'SUCCESS') {
                  const fileUrl = data.data.fileUrl;
                  if (fileUrl) {
                    triggerDownload(fileUrl);
                  } else {
                    console.error('File URL not found in the response data.');
                  }
                } else {
                  Notify('error', 'Oops! There was an error downloading your files. Please try again ', '');
                }
              }
            });
          }
        } else {
          Notify('error', 'Selected files failed to download', '');
        }
      } else if (selectedFiles.length > 0) {
        const result = await callSingleFileDownload();
        if (result.type === 'singleFileDownload/fulfilled') {
          const fileUrls = result.payload.data.files.map((file) => file.fileUrl);
          triggerDownload(fileUrls[0]);
        }
      } 
    } catch (error) {
      Notify('error', error.message, '');
    } finally {
      setLoading(false);
    }
  };

  // Fetch list of convert option

  const fileConvertData = async (type) => {
    setLoading(true);
    const metadata = await callFetchFileMetadata(type);
    try {
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      }
    } catch (error) {
      Notify('error', error.message, '');
    } finally {
      setLoading(false);
    }
  };

  // Upload file progress and upload file

  const uploadFileHandler = async ({ file, downloadLink, fetchHeaders = {}, isBlob = false, preparedFile = null }) => {
    let simulatedPercent = 0;

    const interval = setInterval(() => {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === file.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
        )
      );
    }, 200);

    try {
      const fileToUpload =
        preparedFile ||
        (isBlob
          ? new File([file], file.name, { type: file.type })
          : new File([await (await fetch(downloadLink, { headers: fetchHeaders })).blob()], file.name, {
              type: file.type
            }));

      const uploadResult = await uploadFileCommon(fileToUpload);

      clearInterval(interval);

      if (uploadResult.success) {
        const { data } = uploadResult;
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === file.id
              ? {
                  ...f,
                  ...data,
                  percent: 100
                }
              : f
          )
        );
      } else {
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) => (f.id === file.id ? { ...f, status: uploadResult.error, percent: 100 } : f))
        );
      }
    } catch (error) {
      clearInterval(interval);
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === file.id ? { ...f, status: 'failed', percent: 100 } : f))
      );
    }
  };
  const handleMenuClick = (key, type) => {
    if (type === 'source') {
      setSource(key);
    } else if (type === 'target') {
      setTarget(key);
    }

    const newConvertValue = `${type === 'source' ? key : source}-to-${type === 'target' ? key : target}`;
    if (params.has('convert')) {
      params.set('convert', newConvertValue);
    } else {
      params.append('convert', newConvertValue);
    }
    navigate(`?${params.toString()}`);
  };

  const downloadZip = async (data) => {
    const fileIds = data.map((file) => file.id);
    const requestData = { filesId: fileIds };
    socket.emit('join', userId);
    socket.off('zip-status');
    const result = await callMultipleDownload(requestData);
    const { type, payload } = result;

    if (type === 'multipleDownload/fulfilled') {
      if (payload.success) {
        triggerDownload(payload.data.fileUrl);
        Notify('success', strings.DOWNLOAD_SUCCESS_MSG, '');
      } else {
        Notify('success', 'We will notify after converting in zip', '');
        socket.on('zip-status', (data) => {
          if (!data.data.processing) {
            if (data.data.status === 'SUCCESS') {
              notification.success({
                message: 'Success! Your file has been zip and it ready for download.',
                placement: 'topRight',
                duration: 5
              });
              const fileUrl = data.data.fileUrl;
              if (fileUrl) {
                triggerDownload(fileUrl);
              } else {
                console.error('File URL not found in the response data.');
              }
            } else {
              notification.error({
                message: 'Oops! There was an error downloading your files. Please try again ',
                placement: 'topRight',
                duration: 5
              });
            }
          }
        });
      }
    } else {
      Notify('error', 'Selected files failed to download', '');
    }
  };
  const navigateToDashboard = () => {
    navigate(`/${lang}/dashboard`);
    scrollToTop();
  };
  return (
    <>
      <FileConvertComponent
        move={move}
        setLoading={setLoading}
        isLoading={isLoading}
        percent={percent}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        handleRegister={handleRegister}
        checkoutModalOpen={checkoutModalOpen}
        clientSecret={clientSecret}
        options={options}
        stripePromise={stripePromise}
        customer={customer}
        setOpenDropdowns={setOpenDropdowns}
        openDropdowns={openDropdowns}
        selectedFiles={selectedFiles}
        handleDeleteFile={handleDeleteFile}
        setSelectedFiles={setSelectedFiles}
        validationErrors={validationErrors}
        handleSelectConvertTo={handleSelectConvertTo}
        limitExtendMessage={limitExtendMessage}
        conversionErrors={conversionErrors}
        setConversionErrors={setConversionErrors}
        setShowThankyouPopup={setShowThankyouPopup}
        onFileMetadata={fileConvertData}
        metadata={metadata}
        setUploadInProgress={setUploadInProgress}
        uploadInProgress={uploadInProgress}
        uploadFileCommon={uploadFileCommon}
        setLimitExtendMessage={setLimitExtendMessage}
        uploadFileHandler={uploadFileHandler}
        dashRegister={dashRegister}
        source={source}
        target={target}
        sourceMenu={sourceMenu}
        targetMenu={targetMenu}
        isUpdateTitle={isUpdateTitle}
      />
      {isShowThankyouPopup && (
        <ThankyouModal
          visible={isShowThankyouPopup}
          handleConvertFiles={handleConvertFiles}
          isLoading={isLoading}
          onClose={() => {
            setShowThankyouPopup(false);
            navigate(`/${lang}/dashboard`);
          }}
          isDownload={isDownload}
          navigateToDashboard={navigateToDashboard}
        />
      )}
      {checkoutModalOpen && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModal
            clientSecret={clientSecret}
            checkoutModalOpen={checkoutModalOpen}
            setCheckoutModalOpen={setCheckoutModalOpen}
            customer={customer}
            setShowThankyouPopup={setShowThankyouPopup}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            handleConvertFiles={handleConvertFiles}
            setConversionFileID={setConversionFileID}
          />
        </Elements>
      )}
      {isShowConvertPopup && (
        <PostConvertModal
          isShowConvertPopup={isShowConvertPopup}
          isLoading={isLoading}
          setIsShowConvertPopup={setIsShowConvertPopup}
          setIsUploadModal={setIsUploadModal}
          selectedFiles={selectedFiles}
          socket={socket}
          downloadZip={downloadZip}
          singleFileDownload={triggerDownload}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callpaymentIntent: (data) => dispatch(paymentIntent(data)),
    callUploadFile: (data) => dispatch(uploadFile(data)),
    callSaveAnalytics: (data) => dispatch(saveAnalytics(data)),
    callConvertFiles: (data) => dispatch(convertFile(data)),
    callDownloadFiles: (data) => dispatch(downloadFile(data)),
    callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data)),
    callCheckSettingBanIp: (data) => dispatch(checkSettingBanIp(data)),
    callPostUpload: (data) => dispatch(postUploadFile(data)),
    callSaveFile: (data) => dispatch(saveFiles(data)),
    callMultipleDownload: (data) => dispatch(multipleDownload(data)),
    callSingleFileDownload: () => dispatch(singleFileDownload())
  };
};

export default connect(null, mapDispatchToProps)(FileConvert);
