import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const singleFileDownload = createAsyncThunk('singleFileDownload', async () => {
  try {
    const response = await AxiosInstance.get(`/file/list-download-files`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response?.data?.message || error.response?.data?.detail;
    } else {
      throw error;
    }
  }
});

const singleFileDownloadSlice = createSlice({
  name: 'singleFileDownload',
  initialState: {
    isLoading: false,
    singleDownloadFilesData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleFileDownload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleFileDownload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleDownloadFilesData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(singleFileDownload.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action?.error?.message || action?.error?.detail;
      });
  }
});

export default singleFileDownloadSlice.reducer;
