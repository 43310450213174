import { useEffect, useRef, useState } from 'react';
import { all_Icon, cancle_btn } from '../../../../assets/images/dashboard';
import Search from 'antd/es/input/Search';
import { fieldsImage } from '../../../../utils/commonUtils';
import strings from '../../../../utils/strings.json';
import { PiFileAudio } from 'react-icons/pi';
import { Spin } from 'antd';

export const ConverterDropdown = (props) => {
  const { setIsOpen, onSelectConvertTo, metadata, isLoading = false } = props;
  const [selectedField, setSelectedField] = useState('All');
  const dropdownRef = useRef(null);

  const handleFieldChange = (field) => {
    setSelectedField(field);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);
  // Get all output formats or formats for the selected category
  const getDisplayFormats = () => {
    if (selectedField === 'All') {
      // Collect all formats from each category if "All" is selected
      return metadata?.flatMap((item) => item.outputFormats);
    } else {
      // Get formats for the selected category
      return metadata.find((item) => item.category === selectedField)?.outputFormats || [];
    }
  };

  return (
    <div className='custom-select-popup' ref={dropdownRef}>
      <div className='popup'>
        <Spin spinning={isLoading}>
          <div className='close-btn'>
            <img src={cancle_btn} alt='Close' onClick={() => setIsOpen(false)} />
          </div>
          <div className='popup-row'>
            <Search placeholder='Search...' style={{ width: '100%' }} />
          </div>
          <div className='popup-row columns'>
            <div className='column first-column'>
              <div className='fields-list'>
                <div
                  className={`field-item ${selectedField === 'All' ? 'selected' : ''}`}
                  onClick={() => handleFieldChange('All')}
                >
                  <img src={all_Icon} alt='All' />
                  <span>{strings.ALL}</span>
                </div>
                {metadata?.map((field, index) => {
                  const matchingImage = fieldsImage.find(
                    (imageObj) => imageObj.field.toLowerCase() === field.category.toLowerCase()
                  );
                  return (
                    <div
                      key={index}
                      className={`field-item ${selectedField === field.category ? 'selected' : ''}`}
                      onClick={() => handleFieldChange(field.category)}
                    >
                      {field.category === 'audio' ? (
                        <PiFileAudio color={'rgb(0, 118, 206)'} />
                      ) : (
                        <img src={matchingImage?.image} alt={field.category} />
                      )}
                      <span>{field.category}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='column second-column'>
              <ul>
                {getDisplayFormats().map((option, index) => (
                  <li key={index} onClick={() => onSelectConvertTo(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};
