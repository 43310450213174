import React from 'react';

const SelectFilesComponent = (props) => {
  const {  handleDrop, handleFileChange,onDismiss } = props;

  return (
    <div className={`drag-drop-element ${onDismiss ? 'no-pointer' : ''}`}>
      <div
        className='drop-area'
        onDrop={onDismiss ? null : handleDrop}
        onDragOver={onDismiss ? null : (event)=>event.preventDefault()}
        style={{
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: onDismiss ? 'default' : 'pointer'
        }}
      >
        <input
          type='file'
          id='drag_drop_file'
          multiple
          onChange={handleFileChange}
          disabled={onDismiss}
          style={{ display: 'none' }}
        />
        <label htmlFor='drag_drop_file' className={onDismiss ? 'disable-button' : ''}>
          Drag & Drop files here to upload
        </label>
      </div>
    </div>
  );
};

export default SelectFilesComponent;
