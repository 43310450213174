import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Account, Contact, ConvertFile, Cookie, Dashboard, Home, Pricing, Privacy, Terms, Unsubscribe } from '../pages';
import Login from '../components/modals/loginModal';
import PaymentModal from '../components/modals/paymentModal/paymentModal';
import ResetPassword from '../components/modals/resetModal';
import { Cookies } from 'react-cookie';
import { privetRoute, publicRoute } from '../utils/commonUtils';
import { connect } from 'react-redux';
import { getAllPrice, getIPIfy, getIPLocationData, productList } from '../redux/slice';

const AppRoutes = (props) => {
  const { callGetIPIfy, callGetPrice, callGetIPLocationData, callProductList } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get('token');
  const lang = cookies.get('lang');
  const currentRoute = location.pathname.split('/')[2];
  const [searchParams] = useSearchParams();
  const queryCountry = searchParams.get('c');

  useEffect(() => {
    callProductList();
  }, []);

  useEffect(() => {
    if (token && (publicRoute.includes(currentRoute) || !currentRoute)) {
      navigate(`/${lang}/dashboard`);
    } else if (!token && privetRoute.includes(currentRoute)) {
      navigate(`/${lang}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Function to get country based on IP
  const handleGetIpDetails = async () => {
    try {
      const ipResult = await callGetIPIfy();
      if (ipResult.type === 'getIPIfy/fulfilled') {
        const ip = ipResult?.payload?.ip;
        const locationResult = await callGetIPLocationData(ip);
        if (locationResult.type === 'getIPLocationData/fulfilled') {
          const country = locationResult.payload?.data?.currency?.toLowerCase();
          if (country) {
            cookies.set('country', country);
            await callGetPrice(country);
          } else {
            console.log('No valid country data found for the provided IP.');
          }
        }
      }
    } catch (error) {
      console.log('Error during IP and location data fetching:', error);
    }
  };
  
  useEffect(() => {
    const fetchPriceData = async () => {
      if (queryCountry) {
        cookies.set('country', queryCountry);
        await callGetPrice(queryCountry);
      } else {
        handleGetIpDetails();
      }
    };
    fetchPriceData();
  }, []);

  return (
    <Routes>
      <Route path='/:lang/' element={<Home />} />
      <Route path='/:lang/contact' element={<Contact />} />
      <Route path='/:lang/dashboard' element={<Dashboard />} />
      <Route path='/:lang/pricing' element={<Pricing />} />
      <Route path='/:lang/unsubscribe' element={<Unsubscribe />} />
      <Route path='/:lang/account' element={<Account />} />
      <Route path='/:lang/login' element={<Login />} />
      <Route path='/:lang/payment' element={<PaymentModal />} />
      <Route path='/:lang/reset-password' element={<ResetPassword />} />
      <Route path='/:lang/convert' element={<ConvertFile />} />
      <Route path='/:lang/privacy' element={<Privacy />} />
      <Route path='/:lang/terms' element={<Terms />} />
      <Route path='/:lang/cookie' element={<Cookie />} />
    </Routes>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callGetIPIfy: () => dispatch(getIPIfy()),
    callGetPrice: (data) => dispatch(getAllPrice(data)),
    callGetIPLocationData: (data) => dispatch(getIPLocationData(data)),
    callProductList: () => dispatch(productList())
  };
};

export default connect(null, mapDispatchToProps)(AppRoutes);
