import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchFileMetadata } from '../../../../redux/slice';
import Notify from '../../../common/notify';
import ConvertAllTypeComponent from './convertAllType';

const ConvertAllType = ({ callFetchFileMetadata }) => {
  const [metadata, setMetadata] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isManuallySet, setIsManuallySet] = useState(false);
  const [source, setSource] = useState('doc');
  const [target, setTarget] = useState('pdf');
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.size > 0 && !isManuallySet) {
      const convertValue = params.get('convert');
      const [first, second] = convertValue ? convertValue.split('-to-') : [];
      if (first && second) {
        setSource(first);
        setTarget(second);
      } else {
        setSource('doc');
        setTarget('pdf');
      }
    }
  }, [location, params, isManuallySet]);

  const handleConvert = (first, second) => {
    if (first && second) {
      params.set('convert', `${first}-to-${second}`);
    }
    navigate(`?${params.toString()}`);
    const element = document.getElementById('file_convert');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSelectConvertTo = (fileId, convertToOption) => {
    if (fileId === 1 && convertToOption) {
      setSource(convertToOption);
    } else {
      setTarget(convertToOption);
    }

    setIsManuallySet(true);

    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  const toggleDropdown = (fileName) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  const fileConvertData = async (data) => {
    try {
      const metadata = await callFetchFileMetadata(data);
      if (metadata.type === 'files/fetchFileMetadata/fulfilled') {
        setMetadata(metadata.payload);
      }
    } catch (error) {
      Notify('error', error.message, '');
    }
  };
  return (
    <ConvertAllTypeComponent
      onFileMetadata={fileConvertData}
      metadata={metadata}
      toggleDropdown={toggleDropdown}
      handleSelectConvertTo={handleSelectConvertTo}
      openDropdowns={openDropdowns}
      target={target}
      source={source}
      handleConvert={handleConvert}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callFetchFileMetadata: (data) => dispatch(fetchFileMetadata(data))
  };
};

export default connect(null, mapDispatchToProps)(ConvertAllType);
