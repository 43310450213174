import { createSlice } from '@reduxjs/toolkit';

const conversionFileId = createSlice({
  name: 'conversionFiles',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    addFileId: (state, action) => {
  
      state.data = [...state.data, action.payload];
    },
    setFileIds: (state, action) => {
      console.log("state",state)
      state.data = action.payload;
    },
    clearFileIds: (state) => {
      state.data = [];
    },
  },
});

export const { addFileId, setFileIds, clearFileIds } = conversionFileId.actions;

export default conversionFileId.reducer;
