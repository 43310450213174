import React from 'react';
import { connect } from 'react-redux';
import { urlValidation } from '../../../../../redux/slice';
import strings from '../../../../../utils/strings.json';
import GetURLComponent from './getUrl';

const GetURL = (props) => {
  const {
    setIsModalVisible,
    isModalVisible,
    isLoading,
    callUrlValidation,
    uploadFileCommon,
    setSelectedFiles,
    setLoading,
    onDismiss,
    source,
    target
  } = props;

  const params = new URLSearchParams(location.search);

  const handleGetURL = async (url, onClose, setError, setUrl) => {
    setLoading(true);
    const uniqueId = Date.now(); // Generate a unique ID for the file
    let simulatedPercent = 0;
    try {
      const validationResult = await callUrlValidation({ url });
      const { type, payload, error } = validationResult || {};

      if (type === 'urlValidation/fulfilled') {
        const { filename, size, url: validatedUrl } = payload.data;

        const fileExtension = filename.split('.').pop().toLowerCase();
        if (source && fileExtension !== source.toLowerCase() && params.size > 0) {
          setError(`File should be ${source}`);
          setLoading(false);
          setUrl('');
          return;
        }
        if (size === 0) {
          setError(strings.FILE_SIZE_ERROR);
          setLoading(false);
          return;
        }
        onClose();
        setUrl('');
        setSelectedFiles((prevFiles) => [
          ...prevFiles,
          {
            id: uniqueId,
            file: { name: filename, size },
            status: strings.PENDING,
            percent: simulatedPercent
          }
        ]);
        const interval = setInterval(() => {
          if (simulatedPercent < 95) {
            simulatedPercent += 5;
            setSelectedFiles((prevFiles) =>
              prevFiles.map((file) => (file.id === uniqueId ? { ...file, percent: simulatedPercent } : file))
            );
          } else {
            clearInterval(interval);
          }
        }, 0);

        const uploadResult = await uploadFileCommon(null, {
          fileUrl: validatedUrl,
          category: 'publicUrl',
          size,
          name: filename
        });
        clearInterval(interval);
        if (uploadResult.success) {
          setTimeout(() => {
            setSelectedFiles((prevFiles) =>
              prevFiles.map((file) =>
                file.id === uniqueId
                  ? {
                      ...file,
                      ...uploadResult.data,
                      status: strings.SUCCESS,
                      percent: 100,
                      ...(source && params.size > 0 && { convertedTo: target?.toLowerCase() })
                    }
                  : file
              )
            );
          }, 5000);
        } else {
          setSelectedFiles((prevFiles) =>
            prevFiles.map((file) => (file.id === uniqueId ? { ...file, status: strings.FAILED, percent: 100 } : file))
          );
          setError(uploadResult.error);
        }
      } else {
        setError(error?.message || 'URL validation failed');
        setUrl('');
      }
    } catch (error) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => (file.id === uniqueId ? { ...file, status: strings.FAILED, percent: 100 } : file))
      );
      setError(error.message);
      setUrl('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GetURLComponent
      setIsModalVisible={setIsModalVisible}
      isModalVisible={isModalVisible}
      isLoading={isLoading}
      handleGetURL={handleGetURL}
      onDismiss={onDismiss}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUrlValidation: (data) => dispatch(urlValidation(data))
  };
};

export default connect(null, mapDispatchToProps)(GetURL);
