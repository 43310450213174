import { Dropdown, Progress, Spin } from 'antd';
import React, { useState } from 'react';
import { Cookies } from 'react-cookie';
import { FaExclamationTriangle, FaLock, FaShieldAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import {
  Cancle_button,
  document_flow_icon,
  down_arrows,
  file_convert_icon,
  pdf_png_icon,
  pdf_svg_icon,
  right_arrows
} from '../../../../assets/images/dashboard';
import { capitalizeFirstLetter, fileTypeStyles, formatFileSize } from '../../../../utils/commonUtils';
import strings from '../../../../utils/strings.json';
import FileIcon from '../../../fileIcon/fileIcon';
import { ConverterDropdown } from './converterDropdown';
import DropBox from './dropBox';
import './filesConvert.scss';
import GetURL from './getUrl';
import GoogleDrive from './googleDrive';
import { decodeFileName, getProgressDetails } from './helper';
import OneDrive from './oneDrive';
import SelectFiles from './selectFiles';

const FileConvertComponent = (props) => {
  const {
    move,
    percent,
    setIsOpen,
    isOpen,
    setLoading,
    isLoading,
    handleRegister,
    setOpenDropdowns,
    selectedFiles,
    openDropdowns,
    handleDeleteFile,
    validationErrors,
    handleSelectConvertTo,
    limitExtendMessage,
    conversionErrors,
    setConversionErrors,
    onFileMetadata,
    metadata,
    setUploadInProgress,
    uploadInProgress,
    setSelectedFiles,
    uploadFileCommon,
    setLimitExtendMessage,
    uploadFileHandler,
    dashRegister,
    source,
    target,
    sourceMenu,
    targetMenu
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get('token');
  const location = useLocation();
  let isUploading = selectedFiles.some((file) => file.percent !== 100);
  let onDismiss = selectedFiles?.length >= 20 || isUploading;
  const isConvertPage = location.pathname.split('/')[2] === 'convert';
  const params = new URLSearchParams(location.search);

  const toggleDropdown = (fileName) => {
    setConversionErrors({});
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  const getColorForSource = (sourceKey) => {
    const style = fileTypeStyles[sourceKey?.toLowerCase()];
    return style ? style.color : '#000';
  };

  return (
    <>
      <section className='banner-section' id='file_convert'>
        <div className='main_container'>
          <div className='banner-content'>
            {isConvertPage || params.size > 0 ? (
              <div className='dropdown-heading'>
                <Dropdown overlay={sourceMenu} trigger={['click']}>
                  <button className='drop-button' style={{ color: getColorForSource(source) }}>
                    {source}
                    <img src={down_arrows} alt='' />
                  </button>
                </Dropdown>
                <span>to</span>
                <Dropdown overlay={targetMenu} trigger={['click']}>
                  <button className='drop-button' style={{ color: getColorForSource(target) }}>
                    {target}
                    <img src={down_arrows} alt='' />
                  </button>
                </Dropdown>
                <span>Converter</span>
              </div>
            ) : (
              <h1>  { token ? "Upload Files" :"Convert Any Files"}</h1>
            )}
            <p>
              {
                !token?  isConvertPage || params.size > 0
                ? 'Convert Word documents to PDF in a few simple steps. We support all file types, upload any file to get started!'
                : 'Convert archives, documents, images, videos, audio, websites and more online.' : null
              }
            </p>
          </div>
          {move ? (
            <div>
              <div className='drop-box-move-section'>
                <div className='drag-drop-inner'>
                  <div className='drag-drop-element'>
                    <div className='drop-box-move-inner'>
                      <div className='drop-box-convert-icon'>
                        <img src={document_flow_icon} alt='Drop Box' />
                      </div>
                      <div className='drop-box-convert-content'>
                        <h3>File Conversion In Progress</h3>
                        <p>Please wait while we process your files. This may take a few moment</p>
                        <div className='drop-box-progress-bar'>
                          <div className='progress-bar-inner'>
                            <div className='progress-count'>
                              <h5>Converting Files.... {percent}%</h5>
                              <div className='box-move-head-content'>
                                <div className='convert-box-content'>
                                  <img src={pdf_png_icon} alt='Drop Box' />
                                  <span>PNG</span>
                                </div>
                                <div className='convert-box-content'>
                                  <img src={file_convert_icon} alt='Drop Box' className='convert_icon' />
                                </div>
                                <div className='convert-box-content'>
                                  <img src={pdf_svg_icon} alt='Drop Box' />
                                  <span>SVG</span>
                                </div>
                              </div>
                            </div>
                            <Progress
                              id='progressBar'
                              strokeColor={{
                                from: '#0076CE',
                                to: '#0076CE'
                              }}
                              percent={percent}
                              showInfo={false}
                              status='active'
                            />
                            <div className='secure-section'>
                              <div className='ssi-side'>
                                <FaLock color='#0076CE' size={20} />
                                <span>SSl Secure</span>
                              </div>
                              <div className='arrow'>|</div>
                              <div className='ssi-side'>
                                <FaShieldAlt color='#0076CE' size={20} />
                                <span>Trusted Encryption</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spin spinning={isLoading}>
              <div className='drag-drop-inner'>
                <SelectFiles
                  setSelectedFiles={setSelectedFiles}
                  setLimitExtendMessage={setLimitExtendMessage}
                  setUploadInProgress={setUploadInProgress}
                  uploadInProgress={uploadInProgress}
                  uploadFileHandler={uploadFileHandler}
                  onDismiss={onDismiss}
                />
                <div className='banner-device-element'>
                  <div className='device-icon' style={{ opacity: onDismiss ? 0.7 : 1 }}>
                    <DropBox
                      setUploadInProgress={setUploadInProgress}
                      setSelectedFiles={setSelectedFiles}
                      onDismiss={onDismiss}
                      setLimitExtendMessage={setLimitExtendMessage}
                      uploadFileHandler={uploadFileHandler}
                    />
                  </div>
                  <div className='device-icon' style={{ opacity: onDismiss ? 0.7 : 1 }}>
                    <GetURL
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      isLoading={isLoading}
                      uploadFileCommon={uploadFileCommon}
                      setSelectedFiles={setSelectedFiles}
                      setLoading={setLoading}
                      onDismiss={onDismiss}
                      target={target}
                      source={source}
                    />
                  </div>
                  <div className='device-icon' style={{ opacity: onDismiss ? 0.7 : 1 }}>
                    <GoogleDrive
                      setUploadInProgress={setUploadInProgress}
                      setSelectedFiles={setSelectedFiles}
                      onDismiss={onDismiss}
                      uploadFileHandler={uploadFileHandler}
                      setLimitExtendMessage={setLimitExtendMessage}
                    />
                  </div>
                  <div className='device-icon' style={{ opacity: onDismiss ? 0.7 : 1 }}>
                    <OneDrive
                      setUploadInProgress={setUploadInProgress}
                      setSelectedFiles={setSelectedFiles}
                      onDismiss={onDismiss}
                      uploadFileHandler={uploadFileHandler}
                      setLimitExtendMessage={setLimitExtendMessage}
                    />
                  </div>
                </div>
                {selectedFiles.length > 0 && (
                  <div className='file-convert-list-main'>
                    <div className='your-file single_line'>
                      <span>Your Files</span>
                      {limitExtendMessage && <p style={{ color: 'red' }}>{limitExtendMessage}</p>}
                    </div>
                    <div className='file-convert-list-main'>
                      {selectedFiles
                        .slice(0, 20)
                        .map(({ id, file: { name, size }, status, percent, convertedTo, convertedFrom }) => {
                          const fileErrorMessage = conversionErrors[id];
                          const { progressStatus, strokeColor, animatedClass, fileExtension } = getProgressDetails({
                            percent,
                            status,
                            name,
                            strings
                          });
                          return (
                            <div className='wpb_content-wrapper' key={id}>
                              <div className='convert-formate-main'>
                                <div className='file-converting-main'>
                                  <div className='file-convert-img'>
                                    <FileIcon extension={fileExtension} />
                                  </div>
                                  <div className='file-list-inner'>
                                    <p>{decodeFileName(name)}</p>
                                    <span>{formatFileSize(size)}</span>
                                        </div>
                                        <span
                                      className={`errorclass ${status !== strings.SUCCESS && status !== strings.PENDING ? 'error-style' : ''}`}
                                    >
                                      {status !== strings.SUCCESS && status !== strings.PENDING && (
                                        <>
                                          <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                          {`${capitalizeFirstLetter(status)}`}
                                        </>
                                      )}
                                    </span>
                           
                                </div>
                                <div className='file-actions'>
                                  <div className='convert-to-main'>
                                    {(status === strings.SUCCESS || status === strings.PENDING) && (                                     
                                      <>
                                      <div className='convert-formate'>
                                        <span>{fileExtension}</span>
                                        <div className='convert-img'>
                                          <img src={right_arrows} alt='' />
                                        </div>
                                        <div className='convert-option'>
                                          <div
                                            onClick={() => {
                                              if (params.size === 0) {
                                                onFileMetadata(convertedFrom);
                                                toggleDropdown(id, convertedFrom);
                                              }
                                            }}
                                            style={{
                                              cursor: params.size === 0 ? 'pointer' : 'not-allowed'
                                            }}
                                          >
                                            <span style={{ textTransform: convertedTo ? 'uppercase' : 'capitalize' }}>
                                              {convertedTo || strings.CONVERT_TO}
                                            </span>
                                            <img src={down_arrows} alt='' />
                                          </div>
                                        </div>
                                      </div>
                                      <span className={`dropdown-error ${validationErrors[id] ? 'has-error' : ''}`}>
                                        {validationErrors[id] && (
                                          <>
                                            <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                            <span>{validationErrors[id]}</span>
                                          </>
                                        )}
                                      </span>
                                      {fileErrorMessage && (
                                        <span className={`dropdown-error conversion-error`}>
                                          {fileErrorMessage && (
                                            <>
                                              <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                              {fileErrorMessage}
                                            </>
                                          )}
                                        </span>
                                      )}
</>
                                    )}
                                    {openDropdowns[id] && (
                                      <ConverterDropdown
                                        setIsOpen={() => toggleDropdown(id)}
                                        onSelectConvertTo={(option) => handleSelectConvertTo(id, option)}
                                        metadata={metadata}
                                      />
                                    )}
                                    <div className='convert-close'>
                                      <button className='close_imgfield' onClick={() => handleDeleteFile(id)}>
                                        <img src={Cancle_button} alt='' />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Progress
                                className={`wpb_files__progress ${animatedClass}`}
                                percent={percent}
                                status={progressStatus}
                                strokeColor={strokeColor}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {isOpen && <ConverterDropdown setIsOpen={setIsOpen} />}
                {selectedFiles.length > 0 && selectedFiles.some((file) => file.status === strings.SUCCESS) && (
                  <div className='wpb_convert-btn'>
                    <button
                      onClick={token ? dashRegister : handleRegister}
                      className={isLoading || isUploading ? 'btn_disable' : ''}
                      disabled={isLoading || isUploading}
                    >
                      {strings.CONVERT}
                    </button>
                  </div>
                )}
              </div>
            </Spin>
          )}
        </div>
      </section>
    </>
  );
};

export default FileConvertComponent;
