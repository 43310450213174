import React from 'react';
import {
  drop_circle_icon,
  file_encrypted_icon,
  file_icon,
  link_icon
} from '../../../../assets/images/home';
import '../filesToolSection/filesToolSection.scss';
import { drive_icon, one_drive } from '../../../../assets/images/dashboard';

const ConvertDropbox = () => {
  return (
    <>
        <div className='main_container'>
          <div className='tool-about-box-wrap'>
            <div className='tool-about-box'>
              <div className='box-icon'>
                <img src={file_icon} alt='' />
              </div>
              <div className='about-box-content'>
                <h3>Convert All File Types</h3>
                <p>Convert anything from videos and websites to documents and pdfs online.</p>
             </div>
            </div>
            <div className='tool-about-box'>
              <div className='box-icon box-icon-container'>
                <div className='img-container'>
                  <img src={link_icon} alt='' />
                </div>
                <div className='img-container'>
                  <img src={one_drive} alt='' />
                </div>
                <div className='img-container'>
                  <img src={drop_circle_icon} alt='' />
                </div>
                <div className='img-container'>
                  <img src={drive_icon} alt='' />
                </div>
              </div>
              <div className='about-box-content'>
                <h3>Integrate Other Platforms</h3>
                <p>Convert your files from Dropbox, Google Drive, OneDrive and more.</p>
                 </div>
            </div>
            <div className='tool-about-box'>
              <div className='box-icon'>
                <img src={file_encrypted_icon} alt='' />
              </div>
              <div className='about-box-content'>
                <h3>Everything is Encrypted</h3>
                <p>All files are stored encrypted with AES 256. Only you can unlock them.</p>
             </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ConvertDropbox;
