import strings from '../../../../utils/strings.json';

export const getProgressDetails = ({ percent, status, name, strings }) => {
  const isSuccess = percent === 100 && status === strings.SUCCESS;
  const isError = percent === 100 && status !== strings.SUCCESS && status !== strings.PENDING;
  const progressStatus = isSuccess ? strings.SUCCESS : isError ? 'exception' : 'active';
  const strokeColor = isSuccess ? '#52c41a' : isError ? 'red' : undefined;
  const animatedClass = status !== strings.SUCCESS && status !== strings.PENDING ? '' : 'progress-animated';
  const fileExtension = name?.split('.').pop();

  return {
    progressStatus,
    strokeColor,
    animatedClass,
    fileExtension
  };
};

export const isValidUrl = (inputUrl) => {
  try {
    // Use URL constructor to check validity
    new URL(inputUrl);
    return true;
  } catch {
    return false;
  }
};

export const isValidFilesize = (files) => {
  const newFiles = Array.from(files).map((file) => {
    const status = file.size > 600 * 1024 * 1024 ? strings.FILE_SIZE_EXCEEDS_20_MB : strings.PENDING;
    // const status = file.size;
    let fileSize = file.bytes ? file.bytes : file.sizeBytes ? file.sizeBytes : file.size;
    return {
      id: file.id,
      status,
      percent: status === strings.PENDING ? 0 : 100,
      file: { name: file.name, size: fileSize }
    };
  });
  return newFiles;
};

export const decodeFileName = (encodedName) => {
  try {
    return decodeURIComponent(escape(encodedName));
  } catch (error) {
    return encodedName; // Return original if decoding fails
  }
};

export const handleRemoveFocus = (buttonRef) => {
  if (buttonRef.current) {
    buttonRef.current.blur(); // Remove focus from the buttonRef
  }
};

export const fileConvertTypes = [
  { key: 'doc', extension: 'doc', label: 'doc' },
  { key: 'xls', extension: 'xls', label: 'xls' },
  { key: 'pdf', extension: 'pdf', label: 'pdf' },
  { key: 'jpg', extension: 'jpg', label: 'jpg' },
  { key: 'png', extension: 'png', label: 'png' }
];

export const triggerDownload = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = '';
  link.target = '_self';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};