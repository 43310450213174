import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

// Async thunk to fetch IP location data
export const getIPLocationData = createAsyncThunk(
  "getIPLocationData",
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`/setting/ip-location-data/${queryParams}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Slice to manage IP location data state
const ipLocationDataSlice = createSlice({
  name: "ipLocationData",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIPLocationData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIPLocationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIPLocationData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload ;
      });
  },
});

export default ipLocationDataSlice.reducer;
