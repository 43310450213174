import React from 'react';
import './home.scss';
import { bannerIcon1, bannerIcon2, bannerIcon3, hero_banner_pattern } from '../../assets/images/home';
import { FileConvert, FilesConvertInformative } from '../../components/partials/homeComponent';
import EasySteps from '../../components/partials/homeComponent/easySteps';
import ConvertAllType from '../../components/partials/convertComponent/convertAllType';

const HomeComponent = () => {
 
  return (
    <>
      {/* <!------------------ Banner Section Start -----------> */}
      <div className='banner-bg-img'>
        <div className='animation_wrapper animation_banner_img1'>
          <img className='animation_image_wrapper animation_img1' src={hero_banner_pattern} alt='' />
        </div>
        <div className='animation_wrapper animation_banner_img2'>
          <img className='animation_image_wrapper animation_img2' src={bannerIcon2} alt='' />
        </div>
        <div className='animation_wrapper animation_banner_img3'>
          <img className='animation_image_wrapper animation_img3' src={bannerIcon3} alt='' />
        </div>
        <div className='animation_wrapper animation_banner_img4'>
          <img className='animation_image_wrapper animation_img4' src={bannerIcon1} alt='' />
        </div>
      </div>
      <FileConvert />
      {/* <!------------------ Banner Section End -----------> */}
      <ConvertAllType />
      <EasySteps />
      <FilesConvertInformative />
    </>
  );
};

export default HomeComponent;
