import React, { useEffect } from 'react';
import './terms.scss';
import { scrollToTop } from '../../utils/commonUtils';

const TermsComponent = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className='termsAndConditions-main-section'>
      <div className='back-grad-title'>
        <div className='pricing-main-title'>Terms and Conditions</div>
      </div>
      <div className='main_container '>
        <div className='dashboard-box'>
          <div className='card sh mt-4'>
            <div className='docs-section'>
              <h5>1. Ownership</h5>
              <span>
                <p>
                  We are TRACELO ("Company," "we," "us," "our").
                  <br />
                  <br />
                </p>
                <p>
                  We operate the website https://files-convert.com/ (the "Site"), as well as any other related products
                  and services that refer or link to these legal terms (the "Legal Terms") (collectively, the
                  "Services").
                  <br />
                  <br />
                </p>
                <p>
                  You can contact us by email at support@files-convert.com.
                  <br />
                  <br />
                </p>
                <p>
                  These Legal Terms constitute a legally binding agreement made between you, whether personally or on
                  behalf of an entity ("you"), and TRACELO, concerning your access to and use of the Services. You agree
                  that by accessing the Services, you have read, understood, and agreed to be bound by all of these
                  Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, YOU ARE PROHIBITED FROM USING THE
                  SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                  <br />
                  <br />
                </p>
                <p>
                  Supplemental terms and conditions or documents that may be posted on the Services from time to time
                  are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion,
                  to make changes or modifications to these Legal Terms from time to time. We will alert you about any
                  changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive
                  specific notice of each such change. It is your responsibility to periodically review these Legal
                  Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware
                  of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services
                  after the date such revised Legal Terms are posted.
                  <br />
                  <br />
                </p>
                <p>We recommend that you print a copy of these Legal Terms for your records.</p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>2. Acceptance</h5>
              <span>
                <p>
                  This website and tracking service by SMS called Tracelo (hereinafter the Service) is available to any
                  user, for their own consumption, and subject to the terms and conditions: these Terms of Use and
                  Contract (hereinafter TUC), our Privacy Policy and Cookies Policy.{' '}
                </p>
                <p> When you use the Service, you accept these TUC and the other terms and conditions. </p>
                <p> With it, you agree to not use this site for illegal purposes. </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>3. Description of the Service</h5>
              <span>
                <p>
                  {' '}
                  Through the Service, Tracelo allows to track the current position of a person that has consented to be
                  tracked. The Service allows an SMS to be sent to the recipient to tell them that someone wants to know
                  their location. If the recipient of the message expressly accepts, the recipient’s geoposition at that
                  specific moment is shared with the requesting party.{' '}
                </p>
                <p> In no case shall the geolocation go beyond that specific moment in which it has been accepted. </p>
                <p>
                  {' '}
                  <b>
                    {' '}
                    The monthly payment of the service does not guarantee that the person you are looking for can be
                    tracked, the means to achieve this shall only be provided. But if the person is missing, with the
                    mobile phone stolen, turned off or they do not accept the link we send by SMS, we will not be able
                    to get their GPS position.{' '}
                  </b>{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>4. External links</h5>
              <span>
                <p> You may be sent to other websites through links from the Service. </p>
                <p>
                  {' '}
                  However, Tracelo does not control these sites or their content, that are in fact subject to their own
                  terms and conditions. Therefore, Tracelo is NOT responsible for the quality, truthfulness or accuracy
                  of these sites.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>5. Age</h5>
              <span>
                <p>
                  {' '}
                  With regards to the use of the Service, you state that you are of legal age and that you have the
                  legal capacity necessary to be bound by this agreement and to use it in accordance with its terms and
                  conditions, which you fully understand and acknowledge.{' '}
                </p>
                <p>
                  {' '}
                  You state that all the information you provide to access the Service, before and during its usage, is
                  true, complete and accurate.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>6. Intellectual and industrial property</h5>
              <span>
                <p>
                  {' '}
                  The Service’s content and information (among others, data, text, audio, image and computer code), as
                  well as the infrastructure used to provide such content and information, is property of Tracelo or it
                  has the corresponding authorisations for its use.{' '}
                </p>
                <p>
                  {' '}
                  Also, the modification, reproduction, duplication, copy, distribution, sale, resale and other forms of
                  exploitation for commercial or equivalent purposes of the Service is strictly prohibited.{' '}
                </p>
                <p> For any other use of the Service’s content our prior written consent is required. </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>7. User Content</h5>
              <span>
                <p>
                  {' '}
                  You may contribute to the Service by sending us messages to our email address or through the contact
                  form (hereinafter “Content”).{' '}
                </p>
                <p>
                  {' '}
                  We can use your Content in different ways, such as: show it on the website, reformat it, translate it
                  to other languages, edit it to provide clarity, correct errors, promote it or distribute it.{' '}
                </p>
                <p>
                  {' '}
                  Therefore, by submitting Content to us you grant Tracelo worldwide, non-exclusive royalty-free
                  licence, until the content is removed, transferable and sub-licensable on that Content.{' '}
                </p>
                <p>
                  {' '}
                  That means that the content remains yours, but Tracelo, due to this license of use, can: a) use,
                  reproduce, modify, adapt, translate, distribute and publish the Content, create derivative works from
                  it, show it and display it around the world, by any known means and for any legitimate purpose; and b)
                  use the name you send with regard to this Content.{' '}
                </p>
                <p>
                  {' '}
                  However, Tracelo reserves the right to not publish content or information that is false, illegal,
                  misleading or contrary to the rights of third parties.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>8. Price and taxes</h5>
              <span>
                <p>
                  Please note that the price of the files-convert.com Subscription may be displayed to you in USD ($),
                  the default currency of the website, or in your local currency. The amount charged to your credit card
                  will reflect the exact price shown on the website at the time of your purchase
                  <br />
                  <br />
                </p>
                <p>To subscribe to the files-convert.com, you should place the order after an account registration.</p>
                <p>
                  While placing the order, you should complete the Payment Information.
                  <br />
                  <br />
                </p>
                <p>The price of the files-convert.com subscription is as follows:</p>
                <p className='ml-1'>
                  During the first 24 hours after registration, You are entitled to test files-convert.com for an amount
                  which is specified on the Site ("Trial Period"). Trial period lasts 24 hours and costs ₹39.95. After
                  the trial period, if you do not cancel the account, your subscription to the service renews on a
                  monthly basis;
                </p>
                <p className='ml-1'>
                  At the end of these 24 hours trial, subject to termination within this period, a subscription is
                  automatically taken out on a monthly basis for a monthly amount, which is specified on the Site.
                  Subject to termination, this subscription is automatically renewed from month to month and the card
                  used is automatically debited.
                </p>
                <p>
                  Subscription automatically renews unless auto-renewal is turned off at least 24-hours before the end
                  of the current subscription month. We can charge you for the future payment subscription if you didn’t
                  unsubscribe from the service.
                  <br />
                  <br />
                </p>
                <p>
                  In case you didn’t unsubscribe from the service, you will be charged for the next subscription period
                  at the start of the next period. You can cancel a subscription through your account or with assistance
                  of our support team via e-mail.
                  <br />
                  <br />
                </p>
                <p>
                  You are required to keep valid credit/debit cards on file to charge for any recurring monthly
                  subscription fees. You are responsible for keeping your credit/debit card details and contact
                  information current while using the files-convert.com.
                </p>
                <p>Your subscription is NOT based on how much you use (or do not use) the files-convert.com.</p>
                <p>
                  After payment execution for a subscription, you will receive a payment notification from us. All your
                  receipts are available by request.
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>9. Payment methods</h5>
              <span>
                <p>
                  The payment of the contracted Service can be made via all major debit/credit cards.
                  <br />
                  <br />
                </p>
                <p>
                  {' '}
                  In this regard, Tracelo informs credit and debit card holders that they are responsible for
                  transactions on the online store. These are made on a secure page, using SSL technology to ensure the
                  security in the transmission of data.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>10. Validity of offers</h5>
              <span>
                <p>
                  {' '}
                  The Service offered, and the prices of any plans or types, shall be available to purchase while they
                  are accessible through this website.{' '}
                </p>
                <p>
                  {' '}
                  In all cases, Tracelo reserves the right to make modifications to the Service that it considers
                  appropriate, being able to update products and services according to the market.{' '}
                </p>
                <p>
                  {' '}
                  With regards to the prices that appear on the website, Tracelo reserves the right to change these
                  without prior warning.{' '}
                </p>
                <p>
                  {' '}
                  We inform you that despite the updates made to the prices of the Service, these may contain errors.
                </p>
                <p>
                  {' '}
                  We shall promptly correct all the errors that appear, but they shall not be binding on the Service.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>11. Guarantees</h5>
              <span>
                <p> All rights that the laws in force guarantee you as a consumer and user are guaranteed. </p>
                <p>
                  {' '}
                  In any case, while Tracelo shall make all reasonable commercial efforts to provide the Service, you
                  understand that: a. the user’s geolocation requires the express consent of the recipient and; b. that
                  even with their consent, the state of technology does not guarantee that their location is always
                  obtained or that it can be done with sufficient precision.{' '}
                </p>
                <p>
                  {' '}
                  In any case, and as far as the law allows, Tracelo reserves the right to reject, suspend or cancel the
                  provision of the Service at its sole discretion.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>12. Responsibility</h5>
              <span>
                <p>
                  {' '}
                  To use the Service, you confirm that: a. you have lawfully obtained the telephone number that you want
                  to track and with its owner’s prior consent and; b. that you do not intend to use the Service for any
                  unlawful purpose.{' '}
                </p>
                <p>
                  {' '}
                  On the other hand, to the extent permitted by the applicable law, Tracelo shall not be responsible for
                  any loss of data or programmes as a result of using the Service.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>13. Security</h5>
              <span>
                <p>
                  {' '}
                  On the other hand, Tracelo ensures the security of the Service in accordance with current
                  technological knowledge.{' '}
                </p>
                <p>
                  {' '}
                  However, Tracelo cannot ensure the complete security of the Service, although it agrees to remedy and
                  implement the appropriate corrective measures to correct any possible security breach as soon as
                  possible.
                </p>
                <p>
                  {' '}
                  The user agrees to notify the Service, immediately and via email to support@files-convert.com, of any
                  loss of information, access or unauthorised use by third parties or any situation that may result in
                  the identity theft of other users.{' '}
                </p>
                <p>
                  {' '}
                  Tracelo shall not be responsible for damages that may occur when using the Service if it is as a
                  result of unforeseeable technical problems or, if they are foreseeable, are unavoidable. Similarly,
                  Tracelo shall not be responsible when the defective action of the user, whether the sender or
                  recipient of the message, causes damage.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>14. Subscription and reimbursement</h5>
              <span>
                <p>
                  {' '}
                  Access the service using a trial of 24 hours for ₹39.95 and after that time if you do not cancel the
                  account, your subscription to the service is monthly for an amount of ₹2495 and will be renewed
                  automatically for the same period of time until its completion.{' '}
                </p>
                <p>
                  {' '}
                  Unless you cancel your subscription, you authorize us to charge the monthly subscription fee
                  corresponding to your payment method. <br />
                  <br />
                </p>
                <p>
                  {' '}
                  You may cancel your subscription to the Service whenever you wish, and you shall continue to have
                  access to it until the end of your monthly invoicing period. To the extent permitted by law, the
                  payments are non-refundable and we do not give refunds or payments for partial periods of the monthly
                  subscription.
                  <br />
                  <br />
                </p>
                <p>
                  {' '}
                  You might have the opportunity to receive a refund in case you are not satisfied with the
                  files-convert.com for any reason. But this does not mean that refunds may be offered in all cases.
                  Your eligibility for a refund may depend on how much time has passed since the transaction, etc. You
                  may be eligible to receive a refund within 30 calendar days from the payment day. If you used
                  files-convert.com during the Trial period without canceling the Subscription, and you were charged the
                  full price and continued using files-convert.com, you won't be eligible for a refund. However, because
                  customer satisfaction is our priority, in certain circumstances, we reserve the discretionary right to
                  issue refunds.
                  <br />
                  <br />
                </p>
                <p>
                  {' '}
                  To cancel the Service or to request a refund, log in to your profile or contact us at:
                  support@files-convert.com
                  <br />
                  <br />
                </p>
                <p>
                  {' '}
                  If the credit card with which you registered on the Service does not have sufficient funds when
                  charging the monthly payment, the account will be canceled at that time.
                  <br />
                  <br />
                  <br />
                </p>
                <p></p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>15. Withdrawal</h5>
              <span>
                <p>
                  {' '}
                  If you contract the Service, you shall be contracting the supply of digital content not provided in
                  physical form.{' '}
                </p>
                <p>
                  {' '}
                  Therefore, and as soon as the content has started to be implemented, you accept and understand that
                  the right of withdrawal, as established in article 103 m) of the General Law on Consumer and User
                  Protection, approved by the Royal Legislative Decree 1/2007, of 16 November, is not applicable to this
                  contract.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>16. Modifications and nullity</h5>
              <span>
                <p>
                  {' '}
                  We may update the terms and conditions of the Service in the future, as well as the characteristics
                  and functions of the Service itself.{' '}
                </p>
                <p>
                  {' '}
                  We shall inform you about the changes in the terms and conditions by placing a notification in a
                  visible place on our website and/or by email.{' '}
                </p>
                <p>
                  {' '}
                  If any clause included in our terms and conditions is declared, fully or partially, null or void, it
                  shall only affect the provision in question or the part that is null or void. The rest of our terms
                  and conditions shall remain in effect and the provision in question, or the affected part of it shall
                  be considered to have been excluded.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>17. Complaints and actions arising from the contract</h5>
              <span>
                <p>
                  Any dispute arising out of or in connection with these Legal Terms, including any question regarding
                  its existence, validity, or termination, shall be referred to and finally resolved by the
                  International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels,
                  Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is
                  considered as the part of this clause. The number of arbitrators shall be two (2). The seat, or legal
                  place, or arbitration shall be Montreal, Canada. The language of the proceedings shall be English. The
                  governing law of these Legal Terms shall be substantive law of Canada.{' '}
                </p>
              </span>
            </div>
            <div className='docs-section'>
              <h5>18. Contact</h5>
              <span>
                <p> For any further clarification, incident or complaint, please contact us through: </p>
                <p className='mt-1'>E-mail: support@files-convert.com</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsComponent;
