import { Progress, Spin } from 'antd';
import './thankyouModal.scss';
import { Home } from '../../../pages';
import strings from '../../../utils/strings.json';
import Modal from '../../common/modal/modal';
import { arrow_right } from '../../../assets/images/home';
import { useEffect, useState } from 'react';
import { downloadIconPlush, success_img } from '../../../assets/images/dashboard';

const ThankyouModal = ({ visible, handleConvertFiles, isLoading, onClose,navigateToDashboard}) => {
  const [progress, setProgress] = useState(0);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);

  // Start progress bar when the modal is visible
  useEffect(() => {
    if (visible) {
      setProgress(10);
      setIsProcessingComplete(false);
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 10; // Increment progress by 10%
          } else {
            clearInterval(interval);
            setIsProcessingComplete(true); // Mark processing as complete
            return 100;
          }
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [visible]);
  return (
    <div>
      <Home />
      <Modal
        isOpen={visible}
        onClose={onClose}
        className='thankyou-info-wrap'
        maskClosable={false}
        closeIcon={false}
        closable={false}
      >
          <div className='thankyou-modal-section'>
            <div className='thankyou-modal-title'>
              <span>Your file is almost ready!</span>
            </div>
            <div className='wrap-top-modal'>
              <div className='body-modal'>
                <div className='modal-thankyou-form'>
                  <div className='thankyou_progress_img'>
                  {!isProcessingComplete ? (
                      <div className="progress-container">
                      <Progress
                        type="circle"
                        percent={progress}
                        width={150}
                        strokeWidth={10}
                        strokeColor="green"
                      />
                    </div>
                    ) : (
                    <img src={success_img} width={140} height={140} alt='Success Animation Images' className='thankyou_progress_animation'/>
                    )}
                  </div>
                </div>
                <div className='description-modal'>{isProcessingComplete ? " Conversion completed! Your file is ready for download!": strings.THANK_YOU_POPUP_INFORMATION_MESSAGE}</div>
            
                <div className='submit-btn-modal submit-mb'>
                  <button className='hl_cta_wrap' 
                  onClick={()=>handleConvertFiles() }
                  disabled={!isProcessingComplete}
                  >
                      {isLoading ? (
                        <Spin size='small' strokeColor="white" /> // Show loader
                      ) : (
                        <>
                           Download Your File
                          <img src={downloadIconPlush} alt='arrow icon' className='arrow-icon' />
                        </>
                      )}
                  </button>
                </div>
                {isProcessingComplete &&
                <div className='submit-btn-modal'>
                  <button className='hl_cta_wrap hl_border_button' onClick={()=>{navigateToDashboard()}} >
                    Convert More files
                    <img src={arrow_right} alt='arrow icon' className='arrow-icon' />
                  </button>
                </div>
}
              </div>
            </div>
          </div>
      </Modal>
    </div>
  );
};

export default ThankyouModal;
