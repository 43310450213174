import { Modal, Progress, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { decodeFileName, getProgressDetails, triggerDownload } from '../../partials/homeComponent/filesConvert/helper';
import { capitalizeFirstLetter, formatFileSize } from '../../../utils/commonUtils';
import FileIcon from '../../fileIcon/fileIcon';
import strings from '../../../utils/strings.json';
import { download_button_icon, downloadSuccessIcon } from '../../../assets/images/dashboard';
import { appendFiles } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const PostConvertModal = ({
  isShowConvertPopup,
  setIsShowConvertPopup,
  selectedFiles,
  socket,
  setIsUploadModal,
  downloadZip,
}) => {
  const [progressData, setProgressData] = useState({});
  const [SingleFileData, setSingleFileData] = useState([]);
  const dispatch = useDispatch();
  const allFilesConverted = selectedFiles.every(({ id }) => progressData[id]?.status === 'success');
  const authenticate = useSelector((state) => state?.authenticate||{});
  const { _id } = authenticate?.getAuthenticateData?.data || '';
  const handleCancel = () => {
    setIsShowConvertPopup(false);
    setIsUploadModal(false);
    localStorage.removeItem("disable");
  };
  useEffect(() => {
    socket.emit('join', _id);
    const notificationHandler = (data) => {
      const { status, fileId, message, errorDetails } = data.data;
      if (status === 'SUCCESS') {
        setProgressData((prev) => ({
          ...prev,
          [fileId]: { percent: 100, status: 'success' }
        }));
      } else if (status === 'FAILED') {
        setProgressData((prev) => ({
          ...prev,
          [fileId]: { percent: 100, status: 'error', errorMessage: errorDetails || message }
        }));
      }
    };

    socket.on('notification', notificationHandler);
    const statusUpdateHandler = (data) => {
      console.log('status-update', data);
      const { _id, exportFileUrl, convertedFileName, conversionStatus } = data.data;
      setSingleFileData((prev) => ({
        ...prev,
        [_id]: { ...prev[_id], exportFileUrl, convertedFileName, conversionStatus, _id }
      }));
      if (data?.data) {
        if (data?.data?.conversionStatus !== 'INPROGRESS') {
          dispatch(appendFiles(data.data));
        }
      }
    };
    socket.on('status-update', statusUpdateHandler);
    return () => {
      socket.off('notification', notificationHandler);
      socket.off('status-update', statusUpdateHandler);
    };
  }, [socket, dispatch]);

  const singleFileDownload = (fileId) => {
    triggerDownload(SingleFileData[fileId]?.exportFileUrl);
  };
  return (
    <div className='convert_post'>
      <Modal
        visible={isShowConvertPopup}
        onOk={() => setIsShowConvertPopup(false)}
        onCancel={handleCancel}
        footer={null}
        className='modal-content urL-modal file-convert-second-modal'
        maskClosable={false}
      >
        {selectedFiles.length > 0 && (
          <div className='file-convert-list-main convert_post'>
            <div className='your-file single_line'>
              <span> Files Convert</span>
            </div>
            <div className='file-convert-list-main'>
              {selectedFiles.slice(0, 20).map(({ id, file: { name, size }, convertedFrom, convertedTo }) => {
                const fileProgress = progressData[id] || { percent: 10, status: 'active' };
                const { progressStatus, strokeColor, animatedClass, fileExtension } = getProgressDetails({
                  percent: fileProgress.percent,
                  status: fileProgress.status,
                  name,
                  strings
                });
                return (
                  <div className='wpb_content-wrapper' key={id}>
                    <div className='convert-formate-main'>
                      <div className='file-converting-main'>
                        <div className='file-convert-img'>
                          <FileIcon extension={convertedFrom} />
                        </div>
                        <div className='file-list-inner'>
                          <p>{decodeFileName(name)}</p>
                          <span>{formatFileSize(size)}</span>
                        </div>
                        <span className={`errorclass ${fileProgress.status === 'error' ? 'error-style' : ''}`}>
                          {fileProgress.status === 'error' && (
                            <>
                              <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                              {capitalizeFirstLetter(fileProgress.errorMessage || 'Failed')}
                            </>
                          )}
                        </span>
                      </div>
                      <div className='file-actions'>
                        <div className='convert-to-main'>
                          {fileProgress.status === 'active' && (
                            <div className='convert-formate'>
                              <span>{fileExtension}</span>
                              <div className='convert-img'>
                                <FileIcon extension={convertedTo} />
                              </div>
                            </div>
                          )}
                          {fileProgress.status === 'success' && (
                            <div className='convert-formate'>
                              <img src={downloadSuccessIcon} alt='Download Button Icon' height={32} width={32} />
                              <Button className='download-icon' onClick={() => singleFileDownload(id, name)}>
                                <img src={download_button_icon} alt='Download Button Icon' height={32} width={32} />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Progress
                      className={`wpb_files__progress ${animatedClass}`}
                      percent={fileProgress.percent}
                      status={progressStatus}
                      strokeColor={strokeColor}
                      showInfo={false}
                    />
                  </div>
                );
              })}
            </div>
            {allFilesConverted && <Button className='download-all-icon' onClick={() => downloadZip(selectedFiles)}>Download file <img src={download_button_icon} alt='Download Button Icon' height={16} width={16} /></Button>}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PostConvertModal;
