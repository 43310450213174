import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { BsArrowRight, BsDownload } from 'react-icons/bs';
import {
  convert_icon,
  delete_button_icon,
  delete_file_icon,
  down_arrows,
  download_button_icon,
  format_icon,
  more_icon,
  right_arrows,
  search_vector,
  tool_tip,
  upload_icon
} from '../../../assets/images/dashboard';
import FileIcon from '../../../components/fileIcon/fileIcon';
import { ConverterDropdown } from '../../../components/partials/homeComponent/filesConvert/converterDropdown';
import { formatDate, formatFileSize, mobileOption } from '../../../utils/commonUtils';
import strings from '../../../utils/strings.json';
import { decodeFileName } from '../../../components/partials/homeComponent/filesConvert/helper';
import { useSelector } from 'react-redux';

const DashFileListingComponent = ({
  metadata,
  isLoading,
  loading,
  paginationProps,
  data,
  onSortChange,
  setSelectedFiles,
  selectedFiles,
  onSearch,
  error,
  search,
  onFileMetadata,
  processing,
  selectedRowKeys,
  setSelectedRowKeys,
  onDownload,
  handleReconvert,
  handleDeleteSelectedFiles,
  handleConvert,
  handleSelectConvertTo,
  convertOptions,
  openDropdowns,
  toggleDropdown,
  setIsUploadModal,
  handleRetryFile,
  fileConvertStatus,
  convertLoading,
  conversionFileId
}) => {
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const conversionFileIds = useSelector((state) => state.conversionFileId.data);

  const fileOption = (record) => (
    <Menu>
      {mobileOption.map((item) => (
        <Menu.Item
          key={item.id}
          onClick={() => {
            if (item.name === 'Download') {
              onDownload([record.exportFileUrl]);
            } else {
              handleDeleteSelectedFiles(record.key);
            }
          }}
        >
          <div className='upload-files-option'>
            <img src={item.image} alt={item.name} style={{ width: 25 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const columns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => onSortChange('type')
      })
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => onSortChange('name')
      }),
      render: (text) => (
        <div
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            maxWidth: '300px',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {decodeFileName(text)}
        </div>
      )
    },
    {
      title: 'Size',
      dataIndex: 'size',
      sorter: true,
      onHeaderCell: (e) => ({
        onClick: () => onSortChange(e.dataIndex)
      })
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      sorter: true,
      onHeaderCell: (e) => ({
        onClick: () => onSortChange(e.dataIndex)
      }),
      render: (_, record) => formatDate(record.lastModified, true)
    },
    {
      title: () =>
        selectedFiles.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              justifyContent: 'end'
            }}
          >
            <button className='document-default-btn convert-btn' onClick={() => handleReconvert()}>
              <img src={convert_icon} alt='Convert Icon' />
              Convert
            </button>
            <button className='document-default-btn download-btn' onClick={() => onDownload()}>
              <img src={download_button_icon} alt='Download Button Icon' />
              Download
            </button>
            <button className='document-default-btn delete-btn' onClick={() => handleDeleteSelectedFiles()}>
              <img src={delete_button_icon} alt='Delete Button Icon' />
              Delete
            </button>
          </div>
        ),
      render: (_, record) => (
        <div
          className=''
          style={{
            display: 'flex',
            alignContent: 'center',
            gap: '30px',
            justifyContent: 'end',
            flexDirection: 'row'
          }}
        >
          <div
            className='file-option'
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end'
            }}
          >
            <div className='formate-info'>
              {convertLoading && conversionFileIds[record.key] === conversionFileId && (
                <button className='convert-file-btn disabled convert-to-btn'>
                  <span className='loader'>
                    <Spin indicator={<LoadingOutlined spin />} size='small' style={{ color: 'green' }} />
                  </span>
                  Converting...
                </button>
              )}
            </div>
            <div className='file-type'>
              {record.convertedFileType}
              <span>
                <img src={right_arrows} alt='' />
              </span>
            </div>
            <div className='formate-info'>
              {processing[record.key] ? (
                <button className='convert-file-btn disabled convert-to-btn'>
                  <span className='loader'>
                    <Spin indicator={<LoadingOutlined spin />} size='small' style={{ color: 'green' }} />
                  </span>
                  Converting...
                </button>
              ) : (
                <>
                  {record?.conversionStatus === 'FAILED' ? (
                    <>
                      <button
                        disabled={record?.uploadFileExpired}
                        onClick={() => handleRetryFile(record.key)}
                        className='retry_btn'
                      >
                        Re-try
                        <img src={right_arrows} alt='' color='white' />
                      </button>
                      <div className='tooltip-container'>
                        <img src={tool_tip} alt='Not found' className='image' />
                        <span className='tooltip-text'>You can re-try your file within 24 hours.</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        className='convert-to-btn'
                        onClick={() => {
                          onFileMetadata(record.convertForm);
                          toggleDropdown(record.key, record.convertForm);
                        }}
                      >
                        <img src={format_icon} alt='Format Icon' />
                        <span style={{ textTransform: convertOptions && convertOptions[record.key] && 'uppercase' }}>
                          {(convertOptions && convertOptions[record.key]) || strings.CONVERT_TO}
                        </span>
                        <img src={down_arrows} alt='' />
                      </button>
                      {convertOptions && convertOptions[record.key] && (
                        <button className='convert-file-btn convert-to-btn' onClick={() => handleConvert(record.key)}>
                          Convert
                          <BsArrowRight color={'#0076ce'} />
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {hoveredRowKey === record.key && (
              <>
                {!record?.conversionStatus === 'FAILED' ? (
                  <>
                    <button
                      className='download-file-btn'
                      onClick={() => onDownload([record.exportFileUrl])}
                      disabled={record?.conversionStatus === 'FAILED'}
                    >
                      <BsDownload size={15} />
                    </button>
                    <button className='delete-file-btn' onClick={() => handleDeleteSelectedFiles([record.key])}>
                      <img src={delete_file_icon} alt='Delete File Icon' />
                    </button>
                  </>
                ) : (
                  <button className='delete-file-btn' onClick={() => handleDeleteSelectedFiles([record.key])}>
                    <img src={delete_file_icon} alt='Delete File Icon' />
                  </button>
                )}
              </>
            )}
          </div>
          {openDropdowns[record.key] && (
            <ConverterDropdown
              setIsOpen={() => toggleDropdown(record.key)}
              onSelectConvertTo={(option) => handleSelectConvertTo(record.key, option)}
              metadata={metadata}
              isLoading={isLoading}
            />
          )}
          <div className='more-option'>
            <Dropdown
              overlay={fileOption(record)}
              trigger={['click']}
              placement='bottomRight'
              overlayclassNameName=' dashboard-header-menu dashboard-option-menu'
            >
              <img src={more_icon} alt='More' onClick={(e) => e.preventDefault()} />
            </Dropdown>
          </div>
        </div>
      )
    }
  ];
  const userListData = data?.data?.files?.map((item) => ({
    key: item._id,
    fileType: (
      <div>
        <FileIcon extension={item.convertedFileType ? item.convertedFileType : item.originalFileType} />
      </div>
    ),
    name: item.convertedFileName,
    size: formatFileSize(item.convertedFileSize),
    exportFileUrl: item.exportFileUrl,
    convertForm: item.convertedFileType,
    conversionStatus: item.conversionStatus,
    convertedFileType: item.convertedFileType,
    lastModified: item.updatedAt,
    uploadFileExpired: item.uploadFileExpired
  }));
  const onRow = (record) => ({
    onMouseEnter: () => setHoveredRowKey(record.key),
    onMouseLeave: () => setHoveredRowKey(null),
    title: record?.conversionStatus === 'FAILED' ? 'This file has an error' : ''
  });
  return (
    <>
      <div className={fileConvertStatus ? 'fileConvertStatus' : ''}>{fileConvertStatus }</div>
      <section className='your_documents_sec'>
        <div className='main_container'>
          <div className='main_row'>
            <div className='main_col title_dock1'>
              <div className='section_title'>
                <h1>Your Documents</h1>
              </div>
            </div>
            <div className='main_col title_dock2'>
              <div className='search_bar'>
                <div className='file_search'>
                  <input
                    type='text'
                    name='Search'
                    value={search}
                    placeholder='Search...'
                    className='form-control'
                    onChange={(e) => onSearch(e.target.value)}
                  />
                  <div className='search-img'>
                    <img src={search_vector} alt='Search Icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className='main_col title_dock3'>
              <div onClick={() => setIsUploadModal(true)}>
                <div className='upload_btn'>
                  <span>
                    <img src={upload_icon} alt='Upload Icon' className='upload-files-icon' />
                    Upload File
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              type: 'checkbox',
              onChange: (selectedKeys, selectedRows) => {
                setSelectedRowKeys(selectedKeys);
                setSelectedFiles(selectedRows);
              },
              getCheckboxProps: (record) => ({
                disabled: processing[record.key] || record?.conversionStatus === 'FAILED'
              })
            }}
            columns={columns}
            dataSource={userListData}
            loading={loading}
            pagination={paginationProps}
            rowKey='key'
            rowClassName={(record) => (processing[record.key] ? 'disabled-row' : '')}
            onRow={onRow}
          />
          {error && <p>Error: {error}</p>}
        </div>
      </section>
    </>
  );
};

export default DashFileListingComponent;
