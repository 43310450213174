import React, { useEffect } from 'react';
import './unsubscribe.scss';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { Spin } from 'antd';
import strings from '../../utils/strings.json';
import { maxEmailLength, scrollToTop } from '../../utils/commonUtils';

const UnsubscribeComponent = ({ onSubscribe, setEmail, email, isLoading, error, setErrors }) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {/* <!---------------- unsubscribe section start -----------------> */}
      <Spin spinning={isLoading}>
        <div className='main-container'>
          <div className='unsubscribe-section'>
            <div className='text-content'>
              <div className='title'>
                <span>{strings.UNSUBSCRIBE}</span>
              </div>
              <div className='description'>
                <p>{strings.CONFIRM_YOUR_EMAIL_ADDRESS_TO_UNSUBSCRIBE_FROM}</p>
              </div>
              <form className='ant-form'>
                <div className='unsubscribe-form-item-control-input'>
                  <div className='unsubscribe-item-control-input-content'>
                    <input
                      name='email'
                      type='email'
                      placeholder='info.filesedit@gmail.com '
                      id='email'
                      aria-required='true'
                      className='unsubscribe-input'
                      value={email}
                      onChange={(e) => {
                        setErrors(null);
                        setEmail(e.target.value);
                      }}
                      maxLength={maxEmailLength}
                    />
                    {error && <p className='error-message-unsubscribe'>{error}</p>}
                    <div className='submit-btn'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          onSubscribe();
                        }}
                      >
                        <MdOutlineMarkEmailRead />
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Spin>
      {/* <!---------------- unsubscribe section End -----------------> */}
    </>
  );
};

export default UnsubscribeComponent;
