import React from 'react';
import { fileTypeStyles } from '../../utils/commonUtils';

const FileIcon = ({ extension }) => {
  const normalizedExtension = extension?.toLowerCase();
  const fileType = fileTypeStyles[normalizedExtension] || fileTypeStyles.default;
  return (
    <>
      <svg
        width='35'
        height='35'
        viewBox='0 0 27 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ height: '35px', width: '35px', textAlign: 'center' }}
      >
        <path
          d='M26.084 9.66602V30.7822C26.084 33.1133 24.1973 35 21.8662 35H5.13184C2.80078 35 0.914062 33.1133 0.914062 30.7822V4.21777C0.914063 1.88672 2.80078 0 5.13184 0H16.418L26.084 9.66602Z'
          fill={fileType.color}
        />
        <path d='M26.084 9.66602H18.1611C17.1973 9.66602 16.418 8.88672 16.418 7.92285V0L26.084 9.66602Z' fill={fileType.dark} />
        <text x='4' y='23' fill='#fff' style={{ fontSize: '7px', textTransform: 'uppercase',fontFamily: '"Inter", sans-serif' }}>
          {extension}
        </text>
      </svg>
    </>
  );
};

export default FileIcon;
