import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';
export const productList = createAsyncThunk('productList', async () => {
  try {
    const response = await AxiosInstance.get(`/pricing/product-list/stripe`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});
const productListSlice = createSlice({
  name: 'productList',
  initialState: {
    isLoading: false,
    productList: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(productList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(productList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productList = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(productList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default productListSlice.reducer;
