import React, { useEffect } from 'react';
import './pricing.scss';
import { cloud, frame, drop_box, google_drive } from '../../assets/images/pricing';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { scrollToTop } from '../../utils/commonUtils';

const PricingComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
  }, []);
  const data = useSelector((state) => state?.getAllPriceData?.getAllPriceData?.data);
  const { amount: trialAmount, symbol: trialSymbol } = data?.[0].trial || {};
  const { amount: standardAmount, symbol: standardSymbol } = data?.[1].standard || {};
  return (
    <>
      {/* <!------------ Pricing Banner Section ------------> */}

      <section className='pricing_banner_wrap site_pr_padding'>
        <div className='main_container'>
          <div className='inside_pricing__content'>
            <h1>Our pricing is affordable</h1>
          </div>
        </div>
      </section>

      {/* <!------------ Pricing Banner Section ------------> */}

      {/* <!---------------- Pricing Section ----------------> */}

      <section className='site__pricing_banner site_pr_padding'>
        <div className='main_container'>
          <div className='common-title'>
            <span>- Buy Plans</span>
            <h2>
              We’ve got a plan <br /> that’s perfect for you
            </h2>
          </div>
          <div className='pricing_content'>
            <div className='pricing-box-1'>
              {/* <div className="pricingCompareBox">
                <div className="pricingCompareText">
                  <span>New User Get A</span>
                  <p>Complete Free 24 Hours Trial</p>
                </div>
                <div className="pricingCompareInput">
                  <div className="pricingEmailBox">
                    <input
                      type="email"
                      placeholder="Enter Your Email Address"
                    />
                  </div>
                  <div className="subscribe-box">
                    <p className="sub-text">Yes, Subscribe For Trial</p>
                    <button className="btn">
                      <img
                        src={arrow_img}
                        alt="Subscibe button"
                        loading="lazy"
                      />
                    </button>
                  </div>
                </div>
              </div> */}
              <div className='pricing-box-info'>
                <div className='pricing-box-inner'>
                  <div className='pricing-icon'>
                    <img src={frame} alt='' />
                  </div>
                  <div className='pricing-content'>
                    <p>There are no limitations on the number of files you want to convert.</p>
                  </div>
                </div>
                <div className='pricing-box-inner'>
                  <div className='pricing-icon'>
                    <img src={frame} alt='' />
                  </div>
                  <div className='pricing-content'>
                    <p>Store files worry-free. Files are fully encrypted and only you have access.</p>
                    <div className='pricing-content-icon'>
                      <img src={drop_box} alt='' />
                      <img src={google_drive} alt='' />
                      <img src={cloud} alt='' />
                    </div>
                  </div>
                </div>
                <div className='pricing-box-inner'>
                  <div className='pricing-icon'>
                    <img src={frame} alt='' />
                  </div>
                  <div className='pricing-content'>
                    <p>Easy access to all your files from any device anywhere in the world.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='pricing-box-2'>
              <div className='pricingCompareBox'>
                <div className='pricingComparePlan'>
                  <a href='javascript:void(0)'>Trial Period</a>
                </div>
                <div className='pricingComparePrice'>
                  <h3>
                    {trialAmount + trialSymbol}
                    <small>/24h</small>
                  </h3>
                  <p>
                    Files Convert operates on a monthly subscription basis. Your initial payment grants unrestricted
                    access to all platform services for 24 hours. After that period, the subscription fee of {standardSymbol + standardAmount} per month applies.
                  </p>
                </div>
                <div className='pricingCompareBtn' onClick={() => navigate(`/`)}>
                  <a href='javascript:void(0)'>Start Trial</a>
                </div>
              </div>
            </div>
            <div className='pricing-box-3'>
              <div className='pricingCompareBox'>
                <div className='pricingComparePlan'>
                  <a href='javascript:void(0)'>Subscription</a>
                </div>
                <div className='pricingComparePrice'>
                  <h3>
                    {standardAmount + standardSymbol} <small>/mоnth</small>
                  </h3>
                  <p>
                    Once the 24 hour trial period has ended, the offer will automatically renew to a monthly
                    subscription. Remember that you can cancel the subscription whenever you want.
                  </p>
                </div>
                <div className='pricingCompareBtn' onClick={() => navigate(`/`)}>
                  <a href='javascript:void(0)'>Subscription Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!---------------- Pricing Section ----------------> */}
    </>
  );
};

export default PricingComponent;
